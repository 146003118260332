import { setDashboard } from 'Auth/service'
import { App } from 'index'

export default [
    {
        name: 'employer.dashboard',
        path: '/employer/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'employer.profile' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: [ 'employer' ]
        },
        beforeEnter(to, from, next) {
            setDashboard('employer')
            next()
        },
        children: [
            {
                name: 'employer.overview',
                path: 'overview',
                component: () => import('./Overview/Overview.vue'),
                meta: { label: () => App.$gettext('Employer overview') }

            },
            {
                name: 'employer.profile',
                path: 'profile',
                component: () => import('./tabs/Profile.vue'),
                meta: { label: () => App.$gettext('Employer profile') }
            },
            {
                name: 'employer.employees',
                path: 'employees',
                component: () => import('./tabs/Employees.vue'),
                meta: { label: () => App.$gettext('Employees') },
                children: [
                    {
                        name: 'employer.employees.add',
                        path: 'add',
                        component: () => import('./tabs/Employees_Add.vue'),
                        meta: { label: () => App.$gettext('Add an employee') }
                    },
                    {
                        name: 'employer.employees.edit',
                        path: 'edit/:riderId',
                        component: () => import('./tabs/RiderWrapper.vue'),
                        meta: {
                            label: () => App.$gettext('Employee/rider profile'),
                            auth: [ 'employer', 'admin' ]
                        },
                        redirect: { name: 'employer.rider.overview' },
                        children: [
                            {
                                name: 'employer.rider.overview',
                                path: 'overview',
                                component: () => import('Rider/Overview/Overview.vue'),
                                meta: { label: () => App.$gettext('Rider overview') },
                                children: [ {
                                    name: 'employer.rider.overview.ShowDetail',
                                    path: 'show/:show(\\d+)',
                                    component: () => import('Rider/Overview/ShowOverview.vue'),
                                    meta: { label: () => App.$gettext('Show detail') }
                                } ]
                            },
                            {
                                name: 'employer.rider.profile',
                                path: 'profile',
                                component: () => import('Rider/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Rider profile') }
                            },

                            {
                                name: 'employer.rider.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'employer.rider.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'employer.rider.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    }
                                    // {
                                    //     name: 'employer.rider.bankAccounts.add_svps',
                                    //     path: 'add_svps',
                                    //     component: () => import('BankAccount/DetailSvps.vue'),
                                    //     meta: { label: () => App.$gettext('Add a bank account at SVPS') }
                                    // },
                                    // {
                                    //     name: 'employer.rider.bankAccounts.edit_svps',
                                    //     path: 'svps/:bankAccountId',
                                    //     component: () => import('BankAccount/DetailSvps.vue'),
                                    //     meta: { label: () => App.$gettext('Edit a SVPS bank account') }
                                    // }
                                ]
                            },


                            {
                                name: 'employer.rider.horses',
                                path: 'horses',
                                component: () => import('Rider/tabs/Horses.vue'),
                                meta: { label: () => App.$gettext('Horses') },
                                children: [
                                    {
                                        name: 'employer.rider.horses.add',
                                        path: 'add',
                                        component: () => import('Rider/tabs/Horses_Add.vue'),
                                        meta: { label: () => App.$gettext('Add a horse') }
                                    },
                                    {
                                        name: 'employer.rider.horses.view',
                                        path: 'view/:id',
                                        component: () => import('Rider/tabs/Horses_View.vue'),
                                        meta: { label: () => App.$gettext('Horse info') },
                                        children: [
                                            {
                                                name: 'employer.rider.horses.view.owner',
                                                path: ':horseOwnerId',
                                                component: () => import('HorseOwner/Detail.vue'),
                                                meta: { label: () => App.$gettext('Horse owner info') }
                                            }
                                        ]
                                    }
                                ]

                            },
                            {
                                name: 'employer.rider.grooms',
                                path: 'grooms',
                                component: () => import('Rider/tabs/Grooms.vue'),
                                meta: { label: () => App.$gettext('Grooms') }
                            },
                            {
                                name: 'employer.rider.documents',
                                path: 'documents',
                                component: () => import('Documents/List.vue'),
                                meta: { label: () => App.$gettext('Employee documents') },
                                children: [ {
                                    name: 'employer.rider.documents.add',
                                    path: 'add',
                                    component: () => import('Documents/Detail.vue'),
                                    meta: { label: () => App.$gettext('Add a document') }
                                } ]
                            },
                            {
                                name: 'employer.rider.venues',
                                path: 'venues',
                                component: () => import('Rider/tabs/Shows.vue'),
                                meta: { label: () => App.$gettext('Venues') },
                                children: [
                                    {
                                        name: 'employer.rider.venues.transactions',
                                        path: ':venueId/transactions',
                                        component: () => import('VenueTransaction/List.vue'),
                                        meta: { label: () => App.$gettext('Transactions') }
                                    },
                                    {
                                        name: 'employer.rider.venue.selfCheckout',
                                        path: ':venueId/selfCheckout',
                                        component: () => import('Rider/SelfCheckout.vue'),
                                        meta: { label: () => App.$gettext('Self-checkout') }
                                    },
                                    {
                                        name: 'employer.rider.venues.VECPedit',
                                        path: ':participationId',
                                        component: () => import('VenueEventCategoryParticipation/HorseParticipationDetail.vue'),
                                        meta: { label: () => App.$gettext('Edit a participation') }
                                    }
                                ]
                            },
                            {
                                name: 'employer.rider.teamMembers',
                                path: 'teamMembers',
                                component: () => import('TeamMember/List.vue'),
                                meta: { label: () => App.$gettext('Accreditation') },
                                children: [
                                    {
                                        name: 'employer.rider.teamMembers.add',
                                        path: 'add',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a team member') },
                                        props: true
                                    },
                                    {
                                        name: 'employer.rider.teamMembers.edit',
                                        path: ':teamMembersId',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a team member') }
                                    },
                                    {
                                        name: 'employer.rider.teamMembers.join',
                                        path: 'join',
                                        component: () => import('TeamMember/JoinTeam.vue'),
                                        meta: { label: () => App.$gettext('Join a team') }
                                    }

                                ]
                            }

                        ]
                    }
                ]
            },
            {
                name: 'employer.bankAccounts',
                path: 'bankAccounts',
                component: () => import('BankAccount/List.vue'),
                meta: { label: () => App.$gettext('Bank accounts') },
                children: [
                    {
                        name: 'employer.bankAccounts.add',
                        path: 'add',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a bank account') }
                    },
                    {
                        name: 'employer.bankAccounts.edit',
                        path: ':bankAccountId',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a bank account') }
                    },
                    {
                        name: 'employer.bankAccounts.add_svps',
                        path: 'add_svps',
                        component: () => import('BankAccount/DetailSvps.vue'),
                        meta: { label: () => App.$gettext('Add a bank account at SVPS') }
                    },
                    {
                        name: 'employer.bankAccounts.edit_svps',
                        path: 'svps/:bankAccountId',
                        component: () => import('BankAccount/DetailSvps.vue'),
                        meta: { label: () => App.$gettext('Edit a SVPS bank account') }
                    }

                ]
            },
            {
                name: 'employer.documents',
                path: 'documents',
                component: () => import('Documents/List.vue'),
                meta: { label: () => App.$gettext('Documents') },
                children: [ {
                    name: 'employer.documents.add',
                    path: 'add',
                    component: () => import('Documents/Detail.vue'),
                    meta: { label: () => App.$gettext('Add a document') }
                } ]
            },
            {
                name: 'employer.reports',
                path: 'reports',
                component: () => import('Reports/Reports.vue'),
                meta: { label: () => App.$gettext('Reports') }
            },
            {
                name: 'employer.balance',
                path: 'balance',
                component: () => import('Balance/List.vue'),
                meta: { label: () => App.$gettext('Balance') }
            },
            {
                name: 'employer.creditCard',
                path: 'creditCard',
                component: () => import('./tabs/CreditCard.vue'),
                meta: { label: () => App.$gettext('Credit Card') }
            },
            {
                name: 'employer.createPayment',
                path: 'createPayment',
                component: () => import('UpfrontPayments/CreatePayment.vue'),
                meta: { label: () => App.$gettext('Create payment') }
            },

            // Team Members
            {
                name: 'employer.teamMembers',
                path: 'teamMembers',
                component: () => import('TeamMember/List.vue'),
                meta: { label: () => App.$gettext('Accreditation') },
                children: [
                    {
                        name: 'employer.teamMembers.join',
                        path: 'join',
                        component: () => import('TeamMember/JoinTeam.vue'),
                        meta: { label: () => App.$gettext('Join a team') }
                    }

                ]
            }
        ]
    }
]
