export default {
    request: function (req, token) {
        if (token)
            this.drivers.http.setHeaders.call(this, req, { Authorization: 'Bearer ' + token })
        else
            req.headers.Authorization && delete req.headers.Authorization
    },

    response: function (res) {
        if (res?.config?.url.includes('/token/refresh') && res.status === 400)
            this.logout()

        const token = res?.data?.token
        if (token && !Array.isArray(token))
            return token
    }
}
