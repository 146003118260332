import CRUDService from 'common/services/crud'

class EmployerCRUDService extends CRUDService {
    constructor() {
        super('employer')
    }

    employees = (employerId, params) =>
        (!employerId)
            ? Promise.reject('Missing Employer ID.')
            : this.$http.get(this.getObjectURL(employerId) + 'employees/', { params })

    addEmployee = (employerId, record) =>
        (!employerId || !record)
            ? Promise.reject('Missing employerId or record data.')
            : this.$http.post(this.getObjectURL(employerId) + 'addEmployee/', record)

    endEmployment = (employerId, employmentId) =>
        (!employerId || !employmentId)
            ? Promise.reject('Missing employerId or employeeId.')
            : this.$http.post(this.getObjectURL(employerId) + 'endEmployment/', { employmentId: employmentId })

    register = (data) =>
        this.$http.post(this.url + 'register/', data)
}

const service = new EmployerCRUDService()
export default service
