import CRUDService from 'common/services/crud'
import { stripEmptyKeys } from '../common/utils'
import { App } from 'index'

export const USER_ROLES = {
    groom: App.$gettext('Groom'),
    trainer: App.$gettext('Trainer'),
    horse_owner: App.$gettext('Horse Owner'),
    family: App.$gettext('Family'),
    rider: App.$gettext('Rider'),
    staff: App.$gettext('Staff'),
    guest: App.$gettext('Guest')
}

class UserCRUDService extends CRUDService {
    constructor() {
        super('user')
    }

    search = (params) => this.$http.get(`${this.url}search/`, { params: stripEmptyKeys(params) })

    hasUsablePassword = ({ username, uid, token }) => !username
        ? Promise.reject('No username specified')
        : this.$http.post(`${this.url}hasUsablePassword/`, {
            username,
            uid,
            token
        })
    USER_ROLES_TYPES = Object.keys(USER_ROLES).map(key => ({ id: key, name: USER_ROLES[key] }))

    saveStripeToken = (userID, token) =>
        (!userID || !token)
            ? Promise.reject('Missing userID or token.')
            : this.$http.post(this.getObjectURL(userID) + 'stripe/', { token })

    // removeStripeCard = (simpleUserId) =>
    //     (!simpleUserId)
    //         ? Promise.reject('Missing simpleUser id')
    //         : this.$http.delete(this.getObjectURL(simpleUserId) + 'stripe/')

    getStripeSetupIntent = (userID) =>
        !userID
            ? Promise.reject('Missing userID.')
            : this.$http.post(this.getObjectURL(userID) + 'create_stripe_setup_intent/')

}

const UserService = new UserCRUDService()
export default UserService
