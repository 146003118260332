import 'bulma-checkbox'
import 'styles/bulma.styl'
import 'styles/base.styl'

import Stripe from 'common/stripe'
import Vue from 'vue'
import VueForm from 'vue-form'
import VueRouter from 'vue-router'
import VueLocalStorage from 'vue-localstorage'

import { Axios, router } from 'config'
import filters from 'common/filters'
import formExtras from 'common/forms/formExtras'
import routerExtras from 'common/routerExtras'
import store from './store'
import packageJson from '../package.json'
import userGuard from 'common/userGuard'
import Clipboard from 'v-clipboard'
import BaseUI from 'common/BaseUI.vue'
import 'common/components'

import ConfigureTranslation from './translationConfig'
import ConfigureAuth from 'Auth/config'

const version = packageJson.version

Vue.use(VueRouter)
Vue.use(VueForm, { validateTag: 'span' })
Vue.use(formExtras)
Vue.use(filters)
Vue.use(routerExtras)
Vue.use(Stripe)
Vue.use(Clipboard)
Vue.use(VueLocalStorage)
Vue.prototype.$http = Axios
Vue.prototype.$IS_DEV = __DEV__
Vue.axios = Axios
Vue.router = router
Vue.use(userGuard, { store })

ConfigureAuth(Vue)
const langPromise = ConfigureTranslation(Vue)

export const App = new Vue({
    router,
    store,
    render: h => h(BaseUI)
})

langPromise.then(() => {
    App.$mount('#app')
    App.$store.commit('setAppUpdateIsAvailable', false)
})

/* eslint-disable */
console.info(`%cEventClearing %c${process.env.NODE_ENV}%c@%c${DJANGO_SETTINGS_MODULE} %cv${version} (${GITINFO.short}@${GITINFO.branch})`,
    'color:hsl(120, 100%, 25%);font-weight:bold;', 'color:initial', 'color:#963', 'color:initial', 'color:#999')
/* eslint-enable */
