import { setDashboard } from 'Auth/service'
import { App } from 'index'

export default [
    {
        name: 'official.dashboard',
        path: '/official/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'official.overview' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: [ 'official' ]
        },
        beforeEnter(to, from, next) {
            setDashboard('official')
            next()
        },
        children: [
            {
                name: 'official.overview',
                path: 'overview',
                component: () => import('./Overview/Overview.vue'),
                meta: { label: () => App.$gettext('Official overview') }

            },
            {
                name: 'official.profile',
                path: 'profile',
                component: () => import('./tabs/Profile.vue'),
                meta: { label: () => App.$gettext('Official profile') }
            },
            {
                name: 'official.bankAccounts',
                path: 'bankAccounts',
                component: () => import('BankAccount/List.vue'),
                meta: { label: () => App.$gettext('Bank accounts') },
                children: [
                    {
                        name: 'official.bankAccounts.add',
                        path: 'add',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a bank account') }
                    },
                    {
                        name: 'official.bankAccounts.edit',
                        path: ':bankAccountId',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a bank account') }
                    }
                ]
            },
            {
                name: 'official.documents',
                path: 'documents',
                component: () => import('Documents/List.vue'),
                meta: { label: () => App.$gettext('Documents') },
                children: [
                    {
                        name: 'official.documents.add',
                        path: 'add',
                        component: () => import('Documents/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a document') }
                    }
                ]
            },
            {
                name: 'official.reports',
                path: 'reports',
                component: () => import('Reports/Reports.vue'),
                meta: { label: () => App.$gettext('Reports') }
            },
            {
                name: 'official.venues',
                path: 'venues',
                component: () => import('Official/tabs/Venues.vue'),
                meta: { label: () => App.$gettext('Venues') },
                children: [
                    {
                        name: 'official.venues.transactions',
                        path: ':venueId/transactions',
                        component: () => import('Official/tabs/TransactionsAtVenue.vue'),
                        meta: { label: () => App.$gettext('Transactions') }
                    }
                ]
            },
            {
                name: 'official.balance',
                path: 'balance',
                component: () => import('Balance/List.vue'),
                meta: { label: () => App.$gettext('Balance') }
            },
            {
                name: 'official.creditCard',
                path: 'creditCard',
                component: () => import('./tabs/CreditCard.vue'),
                meta: { label: () => App.$gettext('Credit Card') }
            },
            {
                name: 'official.teamMembers',
                path: 'teamMembers',
                component: () => import('TeamMember/List.vue'),
                meta: { label: () => App.$gettext('Accreditation') },
                children: [
                    {
                        name: 'official.teamMembers.join',
                        path: 'join',
                        component: () => import('TeamMember/JoinTeam.vue'),
                        meta: { label: () => App.$gettext('Join a team') }
                    }
                ]
            },
            {
                name: 'official.createPayment',
                path: 'createPayment',
                component: () => import('UpfrontPayments/CreatePayment.vue'),
                meta: { label: () => App.$gettext('Create payment'), whitelabelSafe: true }
            }
        ]
    }
]
