<template>
    <div class="modal"
         :class="{ 'is-active': isShowingModal }">
        <div class="modal-background" @click.stop.prevent="cancel"></div>
        <div class="modal-content">
            <div class="box">
                <div class="content has-text-centered">
                    <slot>
                        <h1 class="title">{{ message }}</h1>
                    </slot>
                </div>
                <div class="columns">
                    <div class="column">
                        <button class="button is-medium is-primary is-fullwidth"
                                :class="{ 'is-loading': isConfirmLoading }"
                                @click.stop.prevent="confirm"
                        >Confirm
                        </button>
                    </div>
                    <div class="column">
                        <button @click.stop.prevent="cancel" class="button is-medium is-is-default is-fullwidth">Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button class="modal-close" @click.stop.prevent="cancel"></button>
    </div>
</template>

<script>
    import BaseModal from '@lassehaslev/vue-modal'

    export default {
        mixins: [ BaseModal ],
        props: {
            message: {
                type: String,
                default: 'Are you sure?'
            },
            'auto-close': {
                type: Boolean,
                default: true
            }
        },

        data: function data() {
            return {
                isConfirmLoading: false
            }
        },


        methods: {
            onModalOpen: function onModalOpen() {
                this.isConfirmLoading = false
            },
            confirm: function confirm() {
                this.isConfirmLoading = true
                this.$emit('confirm')
                if (this.autoClose) {
                    this.close()
                }
            },
            cancel: function cancel() {
                this.$emit('cancel')
                this.close()
            }
        }
    }
</script>
