<template>
    <div>
        <div :class="{ 'is-active': isShowingModal }"
             class="modal">
            <div class="modal-background" @click.stop.prevent="cancel" />
            <div class="modal-content">
                <div class="box">
                    <div class="content has-text-centered">
                        <h1 class="title">{{ message }}</h1>
                    </div>
                    <div class="section">
                        <slot name="content" />
                        <div :class="{'is-danger': !!error}" class="file control has-name is-fullwidth">
                            <label class="file-label">
                                <file-upload
                                    ref="upload"
                                    v-model="files"
                                    class="file-input"
                                    @input-file="inputFile"
                                    @input-filter="inputFilter"
                                />
                                <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fas fa-upload" />
                                    </span>
                                    <span class="file-label">
                                        {{ label }}
                                    </span>
                                </span>
                                <span class="file-name">
                                    <span v-if="files && files[0]">{{ files[0].name }}</span>
                                </span>
                            </label>
                        </div>
                        <div v-if="error" class="has-text-danger">
                            <ul v-if="Array.isArray(error)">
                                <li v-for="(err, $index) in error" :key="$index"
                                    v-html="$options.filters.n2br(err)" />
                            </ul>
                            <span v-else v-html="$options.filters.n2br(error)" />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <button key="confirm-upload"
                                    v-translate
                                    :class="{ 'is-loading': isConfirmLoading }"
                                    :disabled="$refs.upload && $refs.upload.active || files.length === 0"
                                    class="button is-large is-primary is-fullwidth" @click.stop.prevent="confirm">
                                Confirm upload
                            </button>
                        </div>
                        <div class="column">
                            <button class="button is-large is-is-default is-fullwidth" @click.stop.prevent="cancel">
                                <translate>Cancel</translate>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="modal-close" @click.stop.prevent="cancel" />
        </div>
    </div>
</template>
<style lang="stylus">
    .file-uploads
        position absolute !important
</style>

<script>
    import BaseModal from '@lassehaslev/vue-modal'

    export default {
        mixins: [ BaseModal ],
        props: {
            message: {
                type: String,
                default: 'Upload file'
            },
            'auto-close': {
                type: Boolean,
                default: false
            },
            filter: {
                type: RegExp,
                default: /\.*$/i
            },
            label: {
                type: String,
                default: 'Choose a file…'
            }
        },

        data: function data() {
            return {
                isConfirmLoading: false,
                files: [],
                error: null,
                formData: null
            }
        },

        methods: {
            onModalOpen: function onModalOpen() {
                this.isConfirmLoading = false
                this.$refs.upload.active = false
                this.files = []
                this.error = null
            },
            confirm: function confirm() {
                this.isConfirmLoading = true

                this.$refs.upload.active = true

                this.formData = new FormData()
                this.formData.append('file', this.files[0].file, this.files[0].name)
                this.$emit('file', this.formData)

                this.files = []

                if (this.autoClose)
                    this.close()

            },
            cancel: function cancel() {
                this.files = []
                this.$emit('cancel')
                this.close()
            },

            /**
             * Has changed
             * @param newFile Object|undefined  Read only
             * @param oldFile Object|undefined  Read only
             * @return undefined
             */
            inputFile: function (newFile, oldFile) {
                // if (newFile && oldFile && !newFile.active && oldFile.active) {
                //     this.formData = new FormData()
                //     this.formData.append('file', newFile.file, newFile.name)
                //     this.$emit('file', this.formData)
                // } else if (!newFile) {
                //     this.error = "No file specified"
                //     this.$refs.upload.active = false
                //     this.isConfirmLoading = false
                // }
            },

            /**
             * Pretreatment
             * @param newFile Object|undefined      Read and write
             * @param oldFile Object|undefined      Read only
             * @param prevent Function              Prevent changing
             * @return undefined
             */
            inputFilter: function (newFile, oldFile, prevent) {

                if (newFile && !oldFile) {
                    this.error = null
                    // Filter non-csv file
                    if (!this.filter.test(newFile.name)) {
                        this.error = `File ${newFile.name} doesn't match filter: ${this.filter}.`
                        return prevent()
                    }
                }

                // Create a blob field
                newFile.blob = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL)
                    newFile.blob = URL.createObjectURL(newFile.file)

            },

            clearErrors() {
                this.$set(this, 'error', null)
            },

            showError(error) {
                this.$refs.upload.active = false
                this.isConfirmLoading = false
                this.$set(this, 'error', error)
            }

        }
    }
</script>
