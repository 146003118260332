import { App } from '../index'

export default [
    {
        name: 'teammember.invite',
        path: '/team-membership-invite/',
        component: () => import('./InviteConfirmation.vue'),
        meta: {
            label: () => App.$gettext('Team membership invite'),
            auth: undefined
        }
    }
]
