import Vue from "vue"

export default {
    state: {},
    mutations: {
        setTableState(state, { key, state: table_state }) {
            Vue.set(state, key, table_state)
        }
    }
}
