<template>
    <span>
        <div class="modal"
             :class="{ 'is-active': isShowingModal }">
            <div class="modal-background" @click.stop.prevent />
            <div class="modal-content">
                <div class="box">
                    <div class="content has-text-centered">
                        <h1 class="title">Log</h1>
                    </div>
                    <div class="section">
                        <ul>
                            <li v-for="message in messages" class="log-message"
                                :class="{'is-warning': message && message.includes(['[WARNING]']) || message.includes(['[!]'])}"
                                v-html="$options.filters.n2br(message)" />
                        </ul>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <button class="button is-large is-is-default is-fullwidth" @click.stop.prevent="close">Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="modal-close" @click.stop.prevent="close" />
        </div>
    </span>
</template>

<style lang="stylus" scoped>
    @import '~styles/$vars.styl'

    .modal-content
        max-width 80vw
        min-width 640px
        width 100%
        @media screen and (min-width: 1280px)
            max-width 1024px
        @media screen and (max-width: 800px)
            min-width initial

    .log-message
        &.is-warning
            color darkgoldenrod
</style>

<script>
    import BaseModal from '@lassehaslev/vue-modal'

    export default {
        mixins: [ BaseModal ],
        data() {
            return { messages: [] }
        },
        methods: {
            onModalOpen() {
            }
        }
    }
</script>
