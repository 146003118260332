import { setDashboard } from 'Auth/service'
import { App } from 'index'

const checkForAcceptedTC = (to, from, next) => {
    // check if T&C has been accepted
    if (!App.$store.getters.forUser.terms_accepted)
        next({ name: 'rider.profile' })
    else
        next()
}
const checkForAcceptedTCandSecure = (to, from, next) => {
    // check if T&C has been accepted
    if (!App.$store.getters.forUser.terms_accepted || !App.$store.state.secureInvitation)
        next({ name: 'rider.profile' })
    else
        next()
}


export default [
    {
        name: 'rider.dashboard',
        path: '/rider/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'rider.overview' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: [ 'rider' ],
            whitelabelSafe: true
        },
        beforeEnter(to, from, next) {
            setDashboard('rider')
            next()
        },
        children: [
            {
                name: 'rider.overview',
                path: 'overview',
                component: () => import('./Overview/Overview.vue'),
                meta: { label: () => App.$gettext('Rider overview') },
                children: [ {
                    name: 'rider.overview.ShowDetail',
                    path: 'show/:show(\\d+)',
                    component: () => import('./Overview/ShowOverview.vue'),
                    meta: { label: () => App.$gettext('Show detail'), whitelabelSafe: true }
                }
                ]
            },
            {
                name: 'rider.profile',
                path: 'profile',
                component: () => import('./tabs/Profile.vue'),
                meta: { label: () => App.$gettext('Rider profile') }
            },
            {
                name: 'rider.horses',
                path: 'horses',
                component: () => import('./tabs/Horses.vue'),
                meta: { label: () => App.$gettext('Horses') },
                beforeEnter: checkForAcceptedTCandSecure,
                children: [
                    {
                        name: 'rider.horses.add',
                        path: 'add',
                        component: () => import('Rider/tabs/Horses_Add.vue'),
                        meta: { label: () => App.$gettext('Add a horse') }
                    },
                    {
                        name: 'rider.horses.view',
                        path: 'view/:id',
                        component: () => import('Rider/tabs/Horses_View.vue'),
                        meta: { label: () => App.$gettext('Horse info') },
                        children: [
                            {
                                name: 'rider.horses.view.owner',
                                path: ':horseOwnerId',
                                component: () => import('HorseOwner/Detail.vue'),
                                meta: { label: () => App.$gettext('Horse owner info') }
                            }
                        ]
                    }
                ]
            },
            {
                name: 'rider.grooms',
                path: 'grooms',
                component: () => import('./tabs/Grooms.vue'),
                meta: { label: () => App.$gettext('Grooms') },
                beforeEnter: checkForAcceptedTCandSecure,
                children: [
                    {
                        name: 'rider.grooms.add',
                        path: 'add',
                        component: () => import('Rider/tabs/Grooms_Add.vue'),
                        meta: { label: () => App.$gettext('Add a groom') }
                    },
                    {
                        name: 'rider.grooms.edit',
                        path: 'edit/:groomId',
                        component: () => import('Rider/tabs/Grooms_Edit.vue'),
                        meta: { label: () => App.$gettext('Edit a groom') }
                    }
                ]
            },
            {
                name: 'rider.bankAccounts',
                path: 'bankAccounts',
                component: () => import('BankAccount/List.vue'),
                meta: { label: () => App.$gettext('Bank accounts') },
                beforeEnter: checkForAcceptedTCandSecure,
                children: [
                    {
                        name: 'rider.bankAccounts.add',
                        path: 'add',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a bank account') }
                    },
                    // {
                    //     name: 'rider.bankAccounts.add_svps',
                    //     path: 'add_svps',
                    //     component: () => import('BankAccount/DetailSvps.vue'),
                    //     meta: { label: () => App.$gettext('Add a bank account at SVPS') }
                    // },
                    {
                        name: 'rider.bankAccounts.edit',
                        path: ':bankAccountId',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a bank account') }
                    }
                    // {
                    //     name: 'rider.bankAccounts.edit_svps',
                    //     path: ':bankAccountId',
                    //     component: () => import('BankAccount/DetailSvps.vue'),
                    //     meta: { label: () => App.$gettext('Edit a SVPS bank account') }
                    // }
                ]
            },
            {
                name: 'rider.employment',
                path: 'employment',
                component: () => import('Rider/tabs/Employment.vue'),
                meta: { label: () => App.$gettext('Employment') },
                beforeEnter: checkForAcceptedTCandSecure
            },
            {
                name: 'rider.teamMembers',
                path: 'teamMembers',
                component: () => import('TeamMember/List.vue'),
                meta: { label: () => App.$gettext('Accreditation') },
                beforeEnter: checkForAcceptedTC,
                children: [
                    {
                        name: 'rider.teamMembers.add',
                        path: 'add',
                        component: () => import('TeamMember/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a team member') },
                        props: true
                    },
                    {
                        name: 'rider.teamMembers.edit',
                        path: ':teamMembersId',
                        component: () => import('TeamMember/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a team member') }
                    },
                    {
                        name: 'rider.teamMembers.join',
                        path: 'join',
                        component: () => import('TeamMember/JoinTeam.vue'),
                        meta: { label: () => App.$gettext('Join a team') }
                    },
                    {
                        name: 'rider.teamMembers.documents',
                        path: 'documents',
                        component: () => import('Documents/List.vue'),
                        meta: { label: () => App.$gettext('Documents') },
                        children: [
                            {
                                name: 'rider.teamMembers.documents.add',
                                path: 'add',
                                component: () => import('Documents/Detail.vue'),
                                meta: { label: () => App.$gettext('Add a document') },
                                props: true
                            }
                        ]
                    }

                ]
            },
            {
                name: 'rider.documents',
                path: 'documents',
                component: () => import('Documents/List.vue'),
                meta: { label: () => App.$gettext('Documents') },
                beforeEnter: checkForAcceptedTCandSecure,
                children: [
                    {
                        name: 'rider.documents.add',
                        path: 'add',
                        component: () => import('Documents/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a document') }
                    }
                ]
            },
            {
                name: 'rider.venues',
                path: 'shows',
                component: () => import('./tabs/Shows.vue'),
                meta: { label: () => App.$gettext('Venues') },
                beforeEnter: checkForAcceptedTCandSecure,
                children: [
                    {
                        name: 'rider.venues.transactions',
                        path: ':venueId/transactions',
                        component: () => import('VenueTransaction/List.vue'),
                        meta: { label: () => App.$gettext('Transactions') }
                    },
                    {
                        name: 'rider.venue.selfCheckout',
                        path: ':riderId/:venueId/selfCheckout',
                        component: () => import('./SelfCheckout.vue'),
                        meta: { label: () => App.$gettext('Self-checkout') }
                    },
                    {
                        name: 'rider.venues.VECPedit',
                        path: 'participation/:participationId',
                        component: () =>
                            import('VenueEventCategoryParticipation/HorseParticipationDetail.vue'),
                        meta: { label: () => App.$gettext('Edit a participation') }
                    }
                ]
            },
            {
                name: 'rider.reports',
                path: 'reports',
                component: () => import('Reports/Reports.vue'),
                meta: { label: () => App.$gettext('Reports') },
                beforeEnter: checkForAcceptedTCandSecure
            },
            {
                name: 'rider.balance',
                path: 'balance',
                component: () => import('Balance/List.vue'),
                meta: { label: () => App.$gettext('Balance') },
                beforeEnter: checkForAcceptedTCandSecure
            },
            {
                name: 'rider.creditCard',
                path: 'creditCard',
                component: () => import('./tabs/CreditCard.vue'),
                meta: { label: () => App.$gettext('Credit Card') },
                beforeEnter: checkForAcceptedTCandSecure
            },
            {
                name: 'rider.createPayment',
                path: 'createPayment',
                component: () => import('UpfrontPayments/CreatePayment.vue'),
                meta: { label: () => App.$gettext('Create payment'), whitelabelSafe: true },
                beforeEnter: checkForAcceptedTCandSecure
            },
            {
                name: 'rider.authRedirect',
                path: 'authRedirect',
                component: () => import('Rider/AuthRedirect.vue'),
                meta: { auth: false, label: () => 'Authenticating..' }
            }

        ]
    }
]
