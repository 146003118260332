import RiderRoutes from 'Rider/routes'
import AdminRoutes from 'Admin/routes'
import EmployerRoutes from 'Employer/routes'
import StableRoutes from 'Stable/routes'
import MerchantRoutes from 'Merchant/routes'
import OrganizerRoutes from 'Organizer/routes'
import VetCheckRoutes from 'VetCheck/routes'
import OfficialRoutes from 'Official/routes'
import SimpleUserRoutes from 'SimpleUser/routes'
import TeamMembershipRoutesGlobal from 'TeamMember/routes'
import WelcomeDeskDashboard from 'WelcomeDesk/routes'
import SignInRoutes from 'SigninDashboard/routes'
import StripeRoutes from 'Stripe/routes'

export default [
    {
        name: 'login',
        path: '/login',
        component: () => import('Auth/Login.vue'),
        meta: { auth: false }
    },
    {
        name: 'registration',
        path: '/registration',
        component: () => import('Auth/Registration.vue'),
        meta: { auth: false }
    },
    {
        name: 'registration.confirmationEmailSent',
        path: '/registration/confirm',
        component: () => import('Auth/RegistrationConfirmationEmailSent.vue'),
        meta: { auth: false }
    },
    {
        name: 'forgotPassword',
        path: '/forgotPassword',
        component: () => import('Auth/ForgotPassword.vue'),
        meta: { auth: false }
    },
    {
        name: 'passwordReset',
        path: '/passwordReset',
        component: () => import('Auth/ResetPassword.vue'),
        meta: { auth: false }
    },
    {
        name: 'chooseDashboard',
        path: '/',
        component: () => import('Auth/Choose.vue'),
        meta: { auth: true }
    },
    {
        name: 'forbidden',
        path: '/403',
        component: () => import('common/Forbidden.vue'),
        meta: { auth: undefined }
    },
    {
        name: 'attendance',
        path: '/attendance/:venueId?',
        component: () => import('WelcomeDesk/SpectatorRegistration.vue'),
        meta: { auth: undefined, label: 'Show spectator registration' }
    }
].concat(
    ...AdminRoutes,
    ...RiderRoutes,
    ...EmployerRoutes,
    ...StableRoutes,
    ...MerchantRoutes,
    ...OrganizerRoutes,
    ...VetCheckRoutes,
    ...OfficialRoutes,
    ...SignInRoutes,
    ...SimpleUserRoutes,
    ...TeamMembershipRoutesGlobal,
    ...WelcomeDeskDashboard,
    ...StripeRoutes,
    // { path: '*', redirect: { name: 'chooseDashboard' } }
    {
        path: '*',
        component: { render: h => h('h1', null, 'Route not found') }
    }
)
