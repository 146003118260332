const state = {
    clipboard: null
}

const getters = {
    clipboard: store => store.clipboard
}

const mutations = {
    setClipboard: (store, item) => {
        store.clipboard = item
    },
    clearClipboard: (store) => {
        store.clipboard = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
