import { setDashboard } from 'Auth/service'
import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { App } from 'index'

export default [
    {
        name: 'welcomedesk.dashboard',
        path: '/welcomedesk/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'welcomedesk.list' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: 'welcomedesk'
        },
        beforeEnter(to, from, next) {
            setDashboard('welcomedesk')
            const assigned_venue_id = App.$auth.user().assigned_venue
            if (!assigned_venue_id) {
                App.notify('User doesn\'t have an assigned venue!', 'danger')
                next(false)
                return
            }

            VenueService.get(assigned_venue_id).then((venue) => {
                setVenue(venue)
                next()
            }).catch((err) => {
                console.error(err)
                unsetVenue()
                next(err)
                throw err
            })
        },
        children: [
            {
                name: 'welcomedesk.list',
                path: 'list',
                component: () => import('./AttendeeList.vue'),
                meta: { label: () => 'Welcome desk' }
            },
            {
                name: 'welcomedesk.add',
                path: 'add',
                component: () => import('WelcomeDesk/Detail.vue'),
                meta: { label: () => App.$gettext('Add attendee') }
            }

        ]
    },
    {
        name: 'welcomedesk.invite',
        path: '/attendee-invite/',
        component: () => import('WelcomeDesk/snippets/InviteConfirmation.vue'),
        meta: {
            label: () => App.$gettext('Attendees invite'),
            auth: undefined
        }
    }
]
