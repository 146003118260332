<template>
    <modal :visible="loading" v-if="loading" id="app-spinner" :closable="false">
        <moon-loader size="120px" color="#fff"></moon-loader>
    </modal>
</template>

<style lang="stylus">
    #app-spinner
        .modal-content
            width 250px
            overflow hidden
            min-height 120px
        .modal-background
            background rgba(#000, .8)
        .v-moon
            margin 0 auto
</style>

<script>
    import { Modal } from 'vue-bulma-modal'

    export default {
        components: {
            Modal
        },

        data() {
            return {
                timer: null,
                loading: false
            }
        },

        created(){
            this.$root.showSpinner = () => {
                clearTimeout(this.timer)
                this.timer = setTimeout(() => this.toggle(true), 300)
            }
            this.$root.hideSpinner = () => {
                clearTimeout(this.timer)
                this.toggle(false)
            }
        },

        methods: {
            toggle(show)
            {
                this.loading = show
            }
        }

    }
</script>
