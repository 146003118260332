import Axios from 'axios'
import VueRouter from 'vue-router'
import routes from './routes'
import { App } from 'index'
import axiosDefaults from 'axios/lib/defaults'
import isEqual from 'lodash.isequal'
import Vue from 'vue'
import { detectIE } from 'common/utils'
import * as Sentry from '@sentry/vue'
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations'

if (!__DEV__)
    Sentry.init({
        enabled: !__DEV__,
        environment: process.env.NODE_ENV,
        release: GITINFO.long,
        dsn: 'https://2a8a69001bc548d08b15e38da3f29c43@o400671.ingest.sentry.io/5259288',
        integrations: [
            new RewriteFramesIntegration()
        ],
        debug: __DEV__,
        normalizeDepth: 5,

        // Vue specific
        Vue: Vue,
        logErrors: __DEV__,
        attachProps: true,
        attachStacktrace: true
    })

// AXIOS
const queue = []
const originalAdapter = axiosDefaults.adapter

const findInQueue = ({ query, url, data, params }) =>
    queue.find(req => isEqual(req.query, query) && req.url === url && req.data === data && isEqual(req.params, params))

const serializeRequest = (config) => ({
    data: config.data || null,
    query: { ...config.query },
    url: config.url,
    params: config.params
})

Axios.interceptors.request.use(config => {
    App.hideSpinner && App.showSpinner()

    if (detectIE() !== false)
        config.headers['Pragma'] = 'no-cache'

    const language = App.$store.getters.language
    if (language)
        config.headers['Accept-Language'] = language

    config.adapter = (config) => {
        const existingRequest = findInQueue(serializeRequest(config))
        if (existingRequest)
            return existingRequest.promise

        let timer
        queue.push({
            ...serializeRequest(config),

            promise: originalAdapter(config).then(response => {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    const index = findInQueue(config)
                    queue.splice(index, 1)
                }, 10)
                return response
            }).catch(err => {
                // remove immediately from queue
                const index = findInQueue(config)
                queue.splice(index, 1)
                return Promise.reject(err)
            })
        })

        return findInQueue(serializeRequest(config)).promise
    }
    return config
})

Axios.interceptors.response.use(
    (response) => {
        App.hideSpinner && App.hideSpinner()

        if (response.config.url.includes('/api/token/') || response.config.url.includes('/api/auth/'))
            return response

        if (response.status === 200 || response.status === 201)
            return response.data

        return response
    },
    (error) => {
        App.hideSpinner && App.hideSpinner()

        if (error.response) {
            if (error.response.status === 503) {
                App.notify(App.$gettext('Server is currently unavailable due to maintenance. '
                    + 'Please try again in few minutes.'), 'warning')
                setTimeout(() => {
                    window.location.reload()
                }, 5000)
                return Promise.reject(error)
            }

            Sentry.setUser(App.$auth.user())
            if (error.response.status > 500) {
                Sentry.setContext('extra', {
                    response: error.response,
                    config: error.config,
                    stack: error.stack
                })
                Sentry.captureMessage(error)
            }

            if (error.response.status === 500) {
                App.notify('An error occurred on the server. Developers have been notified.', 'warning')
                console.error('Request Error:', error)
            } else if (error.response.status === 403) {
                App.notify('Permission denied.', 'warning')
                console.warn('403 Forbidden')
            }

            if (error.response.status === 401) {
                console.error('401 Not authorized')
                App.$auth.logout()
                return
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Request Error:', error.message)
            Sentry.setContext('extra', error.response ? {
                response: error.response,
                config: error.config,
                stack: error.stack
            } : { error })
            Sentry.captureMessage(error, { level: "info" })
        }

        return Promise.reject(error)
    }
)

const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
    App && App.$store.commit('toggleHamburger', false)

    // eslint-disable-next-line no-console
    __DEV__ && console.info('Route change (from -> to):', from.name, from.params, '-->', to.name, to.params)

    const env = __STAGING__ ? '[staging] ' : __PROD__ ? '' : '[local] '
    let suffix
    try {
        suffix = to.meta.label ? ` - ${typeof to.meta.label === 'function' ? to.meta.label() : to.meta.label}` : ''
    } catch (e) {
        suffix = ''
    }
    document.title = `${env}EventClearing${suffix}`
    next()
})

router.afterEach((to, from) => {
    const DOMAIN_IS_WHITELABELED = (App && App.$store.state.domainIsWhitelabeled) || false

    if (DOMAIN_IS_WHITELABELED && App.$auth.check('rider')
        && to.meta.whitelabelSafe !== true
        && to.name !== 'chooseDashboard' && to.name !== 'login'
        && to.name !== 'admin.venues.list' && to.name !== 'rider.authRedirect'
    )
        if (to.name !== 'rider.overview') {
            console.log('Redirecting to / (because whitelabel)')
            App.$router.push('/')
        }

})

router.onError(error => {
    const pattern = /Loading chunk (\d)+ failed/g
    const isChunkLoadFailed = error.message.match(pattern)
    if (isChunkLoadFailed)
        App.$store.commit('setAppUpdateIsAvailable')
})

export {
    Axios,
    router
}
