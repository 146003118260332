<template>
    <div class="stripe-credit-card field" :class="{'valid':valid, 'error':error}">
        <div v-if="loading">Loading, please wait...</div>
        <form>
            <div id="stripe-payment-element" ref="stripeElement" class="field" />
            <div v-if="error" class="notification is-warning">
                {{ error }}
            </div>

            <f-button @click="submit">
                <translate>Add credit card</translate>
            </f-button>
        </form>
    </div>
</template>

<style lang="stylus">
    @import '~styles/$vars.styl'

    .StripeElement
        box-shadow inset 0 1px 2px rgba($black, 0.1)
        border 1px solid $input-border
        border-radius 3px
        font-size 1rem
        padding-bottom 0.5em
        padding-left 0.625em
        padding-right 0.625em
        padding-top 0.5em

    .StripeElement--complete
        opacity .8
        border-color $warning

    .stripe-credit-card
        &.valid .StripeElement
            border-color $success

        &.error .StripeElement
            border-color $danger
</style>

<script>
    import fButton from 'common/forms/fButton.vue'
    import { STRIPE_KEY } from 'const'
    import { App } from 'index'

    let stripe

    export default {
        components: { fButton },
        props: { userId: { type: Number, required: true } },

        data() {
            return {
                valid: false,
                error: null,
                token: null,
                stripe: undefined,
                stripeElements: undefined,
                cardElement: undefined,
                setup_intent_client_key: undefined,
                loading: true
            }
        },

        async mounted() {
            stripe = await require('@stripe/stripe-js').loadStripe(STRIPE_KEY, {
                locale: this.$language.current
            })

            // get Stripe.SetupIntent key from backend
            const UserService = require('User/service').default
            const response = await UserService.getStripeSetupIntent(this.userId)
            this.setup_intent_client_key = response.intent
            this.init()
        },

        methods: {
            init() {
                if (typeof stripe === 'undefined' || !this.$refs.stripeElement)
                    return setTimeout(() => {
                        __DEV__ && console.log('waiting for stripe....')
                        this.init()
                    }, 100)

                this.stripeElements = stripe.elements({ clientSecret: this.setup_intent_client_key })
                const card = this.stripeElements.create('payment')
                card.mount('#stripe-payment-element')
                this.cardElement = card
                card.on('ready', () => this.loading = false)

            },

            async submit() {
                let returnURL = window.location.href + '/?setup-complete'
                returnURL = returnURL.replace('//?', '/?')

                App.showSpinner?.()

                const { error } = await stripe.confirmSetup({
                    elements: this.stripeElements,
                    confirmParams: { return_url: returnURL }
                })

                /* if using "card" element: */
                // const { setupIntent, error } = await stripe.confirmCardSetup(
                //     this.setup_intent_client_key,
                //     {
                //         payment_method: {
                //             card: this.cardElement
                //         },
                //         return_url: returnURL
                //         // elements: this.stripeElements,
                //         // confirmParams: { return_url: returnURL }
                //     })
                // if (setupIntent?.status === 'succeeded') {
                //     window.location.search = ""
                //     window.location.reload()
                // }
                /* if using "card" element - end */

                if (error)
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    this.error = error.message

                App.hideSpinner?.()

            }
        }
    }
</script>
