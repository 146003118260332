import CRUDService from 'common/services/crud'
import querystring from 'querystring'
import { stripEmptyKeys } from 'common/utils'

class OfficialCRUDService extends CRUDService {
    constructor() {
        super('official')
    }

    listAtVenue = (venueId, params = {}, params2 = {}) => !venueId
        ? Promise.reject('Missing venue ID.')
        : this.$http.get(this.url + 'listAtVenue/', { params: { venue: venueId, ...params, ...params2 } })

}

const service = new OfficialCRUDService()
export default service

class _OfficialParticipationService extends CRUDService {
    constructor() {
        super('officialParticipation')
    }
}

export const OfficialParticipationService = new _OfficialParticipationService()


class _OfficialTransactionService extends CRUDService {
    constructor() {
        super('officialTransaction')
    }

    venueTransactionDetailsPDF = (params = {}) =>
        window.open(this.url + 'venueTransactionDetailsPDF/?' + querystring.stringify(stripEmptyKeys(params)))

    venueCompensationPDF = (params = {}) =>
        window.open(this.url + 'generateCompensationBill/?' + querystring.stringify(stripEmptyKeys(params)))

    downloadCompensationBills = (venueId) => !venueId
        ? Promise.reject('Missing venue ID.')
        : window.open(this.url + 'downloadCompensationBills/?' + querystring.stringify({ venue: venueId }))
}

export const OfficialTransactionService = new _OfficialTransactionService()

class _OfficialTransactionReportService extends CRUDService {
    constructor() {
        super('officialTransactionsReport')
    }

    generatePDF = (params = {}) =>
        window.open(this.url + 'generatePDF/?' + querystring.stringify(stripEmptyKeys(params)))

    generateCSV = (params = {}) =>
        window.open(this.url + 'generateCSV/?' + querystring.stringify(stripEmptyKeys(params)))

    generateSummaryPDF = (params = {}) =>
        window.open(this.url + 'generateSummaryPDF/?' + querystring.stringify(stripEmptyKeys(params)))

}

export const OfficialTransactionReportService = new _OfficialTransactionReportService()
