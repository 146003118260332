import { setUser } from './service'
import AuthBearer from './bearer'
import { App } from 'index'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.common.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js'

const getAuthTokenKey = () => App.$auth.impersonating()
    ? App.$auth.options.tokenImpersonateKey
    : App.$auth.options.tokenDefaultKey

export default (Vue) => {
    Vue.use(auth, {
        plugins: {
            http: Vue.axios,
            router: Vue.router
        },
        drivers: {
            auth: AuthBearer,
            http: driverHttpAxios,
            router: driverRouterVueRouter
        },
        options: {
            tokenStore: [ 'localStorage' ],
            loginData: {
                url: '/api/token/auth/',
                method: 'POST',
                fetchUser: true,
                baseURL: '',
                redirect: { name: 'chooseDashboard' }
            },
            impersonateData: {
                url: '/api/auth/user/impersonate/',
                method: 'POST',
                redirect: '/'
            },
            unimpersonateData: {
                url: '/api/auth/user/unimpersonate/',
                method: 'POST',
                redirect: '/',
                makeRequest: false
            },
            logoutData: {
                url: '/api/auth/user/logout/',
                method: 'GET',
                redirect: '/',
                makeRequest: false
            },
            tokenDefaultName: 'default_auth_token',
            refreshData: {
                transformRequest: () => {
                    const token = App.$auth.token()
                    if (!token)
                        return
                    return JSON.stringify({ token })
                },
                url: '/api/token/refresh/',
                method: 'POST',
                enabled: true,
                responseType: 'json',
                headers: { 'Content-Type': 'application/json' },
                interval: 10,
                error(err) {
                    console.error('Failed to refresh JWT. Reseting (frontend) user data.', err)
                    localStorage && localStorage.removeItem(getAuthTokenKey())
                    App.$auth.logout()
                    App.$router.push('/')
                }
            },
            fetchData: {
                url: '/api/auth/user/',
                method: 'GET',
                baseURL: '',
                enabled: true
            },
            notFoundRedirect: { path: '/' },
            parseUserData: (data) => {
                if (data && data.data) {
                    setUser(data.data)
                    return data.data
                }
            }
        }
    })

    const origAuthRefresh = Vue.auth.__proto__.refresh
    Vue.auth.__proto__.refresh = data => {
        return origAuthRefresh(data).catch(err => {
            if (err.response?.config?.url.includes('/token/refresh') && err.response?.status === 400) {
                console.warn('[AUTH] Token refresh failed.')
                return
            }
            return Promise.reject(err)
        })
    }
}
