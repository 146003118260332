<template>
    <div class="field">
        <label class="b-checkbox checkbox">
            <input v-model="model" type="checkbox">
            <span class="check" :class="computedClass"/>
            <span v-if="$slots.default" class="control-label"><slot/></span>
        </label>
        <div class="help" v-if="help || $slots.help" style="display: block;">
            <slot name="help">{{ help }}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BCheckbox',
        inheritAttrs: false,
        props: {
            value: { type: Boolean, default: false },
            help: { type: String, default: undefined }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            computedClass() {
                const c = []
                if (this.$attrs['is-info'] !== undefined)
                    c.push('is-info')
                return c.join(' ')
            }
        }
    }
</script>
