import Vue from 'vue'
import Vuex from 'vuex'
import auth from 'Auth/store'
import venue from 'Venue/store'
import createPersistedState from 'vuex-persistedstate'
import tableState from 'common/table/store'
import taxConfiguration from 'TaxConfiguration/store'

Vue.use(Vuex)

const state = {
    clipboard: null,
    clipboard2: null,
    language: __DEV__ ? 'en' : 'de',
    hamburgerOpen: false,
    routeUser: null,
    domainIsWhitelabeled: false,
    competitionParticipations: null,
    competitionParticipationsIds: null,
    secureInvitation: true,
    updateIsAvailable: false
}

const getters = {
    clipboard: state => state.clipboard,
    clipboard2: state => state.clipboard2,
    language: state => state.language,
    hamburgerOpen: state => state.hamburgerOpen,
    routeUser: state => state.routeUser,
    forUser: state => state.routeUser ? state.routeUser : state.auth.user,
    updateIsAvailable: state => state.updateIsAvailable
}

const mutations = {
    setClipboard: (store, data) => {
        store.clipboard = data
    },
    clearClipboard: (store) => {
        store.clipboard = null
    },
    setClipboard2: (store, data) => {
        if (!data.type || !data.source || !data.items)
            throw new Error('Data not compatible for use with this Clipboard.')
        store.clipboard2 = data
    },
    clearClipboard2: (store) => {
        store.clipboard2 = null
    },
    setLanguage: (store, language) => {
        store.language = language
    },
    toggleHamburger: (store, force) => {
        store.hamburgerOpen = force !== undefined ? force : !store.hamburgerOpen
    },
    setRouteUser(store, user) {
        store.routeUser = user
    },
    resetRouteUser(store) {
        store.routeUser = null
    },
    setDomainIsWhitelabeled(store, value) {
        store.domainIsWhitelabeled = Boolean(value)
    },
    setSecureInvitation(store, value) {
        store.secureInvitation = value
    },
    setAppUpdateIsAvailable(store, value=true) {
        store.updateIsAvailable = value
    }
}

const actions = {}

export default new Vuex.Store({
    modules: {
        auth,
        venue,
        tableState,
        taxConfiguration
    },
    plugins: [
        createPersistedState({ storage: window.sessionStorage })
    ],
    state,
    getters,
    mutations,
    actions
})
