export const routerCatchAndLog = err => {
    // if (err && err.name === 'NavigationDuplicated')
    //     __DEV__ && console.warn('[vue-router DEBUG]', err)
    // else
    //     throw err
}

export default {
    install(Vue) {
        Vue.prototype.goParent = function (overrideParams = {}) {
            const parentRoute = this.$route.matched[this.$route.matched.length - 1].parent
            this.$router.push({
                name: parentRoute.name,
                params: { ...parentRoute.params, ...overrideParams }
            }).catch(routerCatchAndLog)
        }

        Vue.prototype.go = function (name, params = {}) {
            if (!name || typeof name !== 'string' || !name.length > 1)
                return

            const routeParams = { ...this.$route.params, ...params }

            if (name[0] === '.') {
                const route = { name: `${this.$route.name}${name}`, params: routeParams }
                this.$router.push(route).catch(routerCatchAndLog)
            } else if (name[0] === '~') {
                const prefix = this.$route.name.split('.').slice(0, -1).join('.')
                const route = { name: `${prefix}.${name.slice(1)}`, params: routeParams }
                this.$router.push(route).catch(routerCatchAndLog)
            } else if (name[0] === '^') {
                const parentRoute = this.$route.matched[this.$route.matched.length - 1].parent
                this.$router.push({ name: parentRoute.name, params: routeParams }).catch(routerCatchAndLog)
            } else
                this.$router.push({ name, params: routeParams }).catch(routerCatchAndLog)
        }

        Vue.prototype.goBack = function () {
            window.history.back()
        }
    }
}
