<template>
    <div v-if="!asIcons" class="select">
        <select name="language" v-model="$language.current" @input="onLangChanged">
            <option v-for="(language, key) in $language.available" :value="key">{{ language }}</option>
        </select>
    </div>
    <div v-else class="buttons has-text-right">
        <a v-for="(language, key) in $language.available"
           @click="$language.current = key"
           :title="language"
           class="flag-icon-wrapper">
            <span :class="flagCSS(key)"></span>
        </a>
    </div>
</template>

<style scoped lang="stylus">
    /* Manually import only used ones to reduce bloat */
    /*@import "~flag-icon-css/css/flag-icon.css"*/

    .flag-icon-background {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
    }

    .flag-icon {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 1.33333333em;
        line-height: 1em;
    }

    .flag-icon:before {
        content: "\00a0";
    }

    .flag-icon.flag-icon-squared {
        width: 1em;
    }

    .flag-icon-gb {
        background-image: url("~flag-icon-css/flags/4x3/gb.svg");
    }

    .flag-icon-de {
        background-image: url("~flag-icon-css/flags/4x3/de.svg");
    }

    .flag-icon-pl {
        background-image: url("~flag-icon-css/flags/4x3/pl.svg");
    }

    .flag-icon-fr {
        background-image: url("~flag-icon-css/flags/4x3/fr.svg");
    }

    .flag-icon-it {
        background-image: url("~flag-icon-css/flags/4x3/it.svg");
    }

    select
        padding-right 2em

    .flag-icon-wrapper
        font-size 1.5em
        margin 0 .35em
</style>

<script>
    import { mapMutations } from 'vuex'

    export default {
        props: {
            asIcons: {
                default: false,
                type: Boolean
            }
        },
        watch: {
            '$language.current'() {
                this.setLanguage(this.$language.current)
                this.$emit('click')
            }
        },
        methods: {
            ...mapMutations([ 'setLanguage' ]),
            onLangChanged() {
                this.$router.go({
                    ...this.$route,
                    force: true
                })
            },
            flagCSS(key) {
                let countryCode = key
                if (countryCode === 'en')
                    countryCode = 'gb'
                return `flag-icon flag-icon-${countryCode}`
            }
        }
    }
</script>
