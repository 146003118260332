import CRUDService from '../common/services/crud'


class MerchantCRUDService extends CRUDService {
    constructor() {
        super('merchant')
    }

}

const service = new MerchantCRUDService()
export default service
