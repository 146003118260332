import { setDashboard } from 'Auth/service'
import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { App } from 'index'

export default [
    {
        name: 'vetcheck.dashboard',
        path: '/vetcheck/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'vetcheck.list' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: 'vetcheck'
        },
        beforeEnter(to, from, next) {
            setDashboard('vetcheck')
            const assigned_venue_id = App.$auth.user().assigned_venue
            if (!assigned_venue_id) {
                App.notify('User doesn\'t have an assigned venue!', 'danger')
                next(false)
                return
            }

            VenueService.get(assigned_venue_id).then((venue) => {
                setVenue(venue)
                next()
            }).catch((err) => {
                console.error(err)
                unsetVenue()
                next(err)
                throw err
            })
        },
        children: [
            {
                name: 'vetcheck.list',
                path: 'list',
                component: () => import('./VetCheckDashboard.vue'),
                meta: { label: () => 'Vet-check view' }
            }
        ]
    }
]
