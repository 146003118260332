<template>
    <span :class="extraClass"
          class="icon"><i v-if="value"
                          class="fa fa-check-square" /><i v-else
                                                          class="fa fa-times" /></span>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            extraClass() {
                return this.value === true ? 'is-success' : 'is-danger'
            }
        }
    }
</script>
