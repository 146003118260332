<template>
    <button :disabled="disabled" class="button"
            type="button" @click.prevent.stop="$emit('click')">
        <span v-if="hasIcon && !hasIconRight" class="icon is-small"><i :class="iconClass" /></span>
        <span v-if="$slots.default || text">
            <slot>{{ text }}</slot>
        </span>
        <span v-if="hasIcon && hasIconRight" class="icon is-small"><i :class="iconClass" /></span>
    </button>
</template>

<script>
    export default {
        props: {
            text: { type: String, default: undefined },
            icon: { type: String, default: undefined },
            disabled: { type: Boolean, default: false },
            hasIconRight: { type: Boolean, default: false }
        },
        computed: {
            hasIcon() {
                return !!this.icon
            },
            iconClass() {
                if (this.icon && /^fa\w? /.test(this.icon))
                    return this.icon

                return `fas ${this.icon}`
            }
        }
    }
</script>
