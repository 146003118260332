<template>
    <a
        v-if="!productionMode && $auth.impersonating()"
        class="button is-warning is-small"
        @click.stop.prevent="unimpersonate"
    ><span class="icon is-small"><i class="fas fa-user-secret fa-lg" /></span><span>Stop impersonating</span></a>
</template>

<style lang="stylus" scoped>
    @import '~styles/$vars'

    .button
        position absolute
        top 3px
        left 3px
        z-index 10
        +from($desktop)
            left initial
            right 3px
</style>

<script>
    export default {
        props: {},
        data: () => ({ productionMode: __PROD__ }),
        methods: {
            unimpersonate() {
                this.$auth.unimpersonate({ redirect: { name: 'admin.dashboard' } })
            }
        }
    }
</script>
