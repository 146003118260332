import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { setDashboard } from 'Auth/service'
import { App } from 'index'

const VenueBaseComponent = {
    render: h => h('router-view'),
    beforeRouteEnter(to, from, next) {
        VenueService.get(to.params.venueId).then((venue) => {
            setVenue(venue)
            next()
        }).catch((err) => {
            console.error(err)
            unsetVenue()
            throw err
        })
    }
}

export default [
    {
        name: 'admin.dashboard',
        path: '/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'admin.venues.list' },
        meta: {
            label: () => App.$gettext('Admin dashboard'),
            auth: [ 'admin' ]
        },
        beforeEnter(to, from, next) {
            setDashboard('admin')
            next()
        },
        children: [
            {
                name: 'admin.venues',
                path: 'venues',
                component: () => import('./VenueWrapper.vue'),
                redirect: { name: 'admin.venues.list' },
                children: [
                    {
                        name: 'admin.venues.list',
                        path: 'list',
                        component: () => import('Venue/List.vue'),
                        meta: { label: () => App.$gettext('Venues') },
                        beforeEnter(to, from, next) {
                            unsetVenue()
                            next()
                        }
                    },
                    {
                        name: 'admin.venues.add',
                        path: 'add',
                        component: () => import('Venue/Detail.vue'),
                        meta: { label: () => App.$gettext('Add new Venue') }
                    },

                    /* VENUE CONFIG */
                    {
                        name: 'admin.venues.config',
                        path: ':venueId(\\d+)/config',
                        component: VenueBaseComponent,
                        redirect: { name: 'admin.venues.edit' },
                        meta: { label: () => App.$gettext('Venues') },
                        children: [
                            {
                                name: 'admin.venues.edit',
                                path: 'edit',
                                component: () => import('Venue/Detail.vue'),
                                meta: { label: () => App.$gettext('Edit Venue') }
                            },

                            /* Venue Event Categories */
                            {
                                name: 'venueEventCategories',
                                path: 'venueEventCategories',
                                redirect: { name: 'venueEventCategories.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'venueEventCategories.list',
                                        path: 'list',
                                        component: () => import('VenueEventCategory/List.vue'),
                                        meta: { label: () => App.$gettext('Venue event categories') }
                                    },
                                    {
                                        name: 'venueEventCategories.add',
                                        path: 'add',
                                        component: () => import('VenueEventCategory/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add new Venue event category') }
                                    },
                                    {
                                        name: 'venueEventCategories.edit',
                                        path: 'edit/:id',
                                        component: () => import('VenueEventCategory/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Venue event category') }
                                    }
                                ]
                            },

                            /* Competitions */
                            {
                                name: 'competitions',
                                path: 'competitions',
                                redirect: { name: 'competitions.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'competitions.list',
                                        path: 'list',
                                        component: () => import('Competition/List.vue'),
                                        meta: { label: () => App.$gettext('Competitions') }
                                    },
                                    {
                                        name: 'competitions.add',
                                        path: 'add',
                                        component: () => import('Competition/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add new Competition') }
                                    },
                                    {
                                        name: 'competitions.edit',
                                        path: 'edit/:id',
                                        component: () => import('Competition/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Competition') }
                                    }
                                ]
                            },

                            /* Consumables */
                            {
                                name: 'venueConsumables',
                                path: 'consumables',
                                redirect: { name: 'venueConsumables.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'venueConsumables.list',
                                        path: 'list',
                                        component: () => import('VenueConsumable/List.vue'),
                                        meta: { label: () => App.$gettext('Consumables') }
                                    },
                                    {
                                        name: 'venueConsumables.add',
                                        path: 'add',
                                        component: () => import('VenueConsumable/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add new Consumable') }
                                    },
                                    {
                                        name: 'venueConsumables.edit',
                                        path: 'edit/:id',
                                        component: () => import('VenueConsumable/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Consumable') }
                                    }
                                ]
                            },

                            /* Tax Configuration */
                            {
                                name: 'taxConfigurations',
                                path: 'taxConfigurations',
                                redirect: { name: 'taxConfigurations.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'taxConfigurations.list',
                                        path: 'list',
                                        component: () => import('TaxConfiguration/List.vue'),
                                        meta: { label: () => App.$gettext('Tax configurations') }
                                    },
                                    {
                                        name: 'taxConfigurations.edit',
                                        path: 'edit/:VEC_ID',
                                        component: () => import('TaxConfiguration/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Tax configuration') }
                                    }
                                ]
                            },

                            /* Venue users */
                            {
                                name: 'users',
                                path: 'users',
                                component: () => import('Venue/Users.vue'),
                                meta: { label: () => App.$gettext('Venue users') }
                            },

                            /* Other */
                            {
                                name: 'other',
                                path: 'other',
                                component: () => import('Venue/Other.vue'),
                                meta: { label: () => App.$gettext('Other') }
                            },

                            /* E-mail */
                            {
                                name: 'emails',
                                path: 'emails',
                                component: () => import('Venue/Emails.vue'),
                                meta: { label: () => App.$gettext('Emails') }
                            },
                            /* Whitelabel */
                            {
                                name: 'whitelabels',
                                path: 'whitelabels',
                                component: () => import('Venue/Whitelabel.vue'),
                                meta: { label: () => App.$gettext('Whitelabels') }
                            }
                        ]
                    },

                    /* VENUE VIEWS */
                    {
                        name: 'admin.venues.views',
                        path: ':venueId/views',
                        component: VenueBaseComponent,
                        redirect: { name: 'participants' },
                        meta: { label: () => App.$gettext('Venue Views') },
                        children: [
                            /* Participants */
                            {
                                name: 'participants',
                                path: 'participants',
                                redirect: { name: 'participants.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'participants.list',
                                        path: 'list',
                                        component: () => import('VenueParticipation/List.vue'),
                                        meta: { label: () => App.$gettext('Participants') }
                                    },
                                    {
                                        name: 'participants.add',
                                        path: 'add',
                                        component: () => import('VenueParticipation/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Participation') }
                                    }
                                ]
                            },

                            /* Officials */
                            {
                                name: 'officials',
                                path: 'officials',
                                redirect: { name: 'officials.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'officials.list',
                                        path: 'list',
                                        component: () => import('OfficialVenueParticipation/List.vue'),
                                        meta: { label: () => App.$gettext('Participants') }
                                    },
                                    {
                                        name: 'officials.add',
                                        path: 'add',
                                        component: () => import('OfficialVenueParticipation/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Participation') }
                                    }
                                ]
                            },

                            /* Start lists */
                            {
                                name: 'startlists',
                                path: 'startlists',
                                redirect: { name: 'startlists.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'startlists.list',
                                        path: 'list',
                                        component: () => import('Competition/StartLists.vue'),
                                        meta: { label: () => App.$gettext('Start lists') }
                                    },
                                    {
                                        name: 'startlists.edit',
                                        path: 'competition/:competitionId',
                                        component: () => import('Competition/StartList_Detail.vue'),
                                        meta: { label: () => App.$gettext('Competition start list') }
                                    }
                                ]
                            },

                            /* Rankings */
                            {
                                name: 'rankings',
                                path: 'rankings',
                                redirect: { name: 'rankings.list' },
                                component: { render: h => h('router-view') },
                                children: [
                                    {
                                        name: 'rankings.list',
                                        path: 'list',
                                        component: () => import('CompetitionRanking/List.vue'),
                                        meta: { label: () => App.$gettext('Rankings') }
                                    },
                                    {
                                        name: 'rankings.view',
                                        path: 'competition/:competitionId',
                                        component: () => import('CompetitionRanking/View.vue'),
                                        meta: { label: () => App.$gettext('Edit Rankings') }
                                    },
                                    {
                                        name: 'rankings.add',
                                        path: 'competition/:competitionId/add',
                                        component: () => import('CompetitionRanking/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Rankings') }
                                    },
                                    {
                                        name: 'rankings.edit',
                                        path: 'competition/:competitionId/edit',
                                        component: () => import('CompetitionRanking/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Rankings') }
                                    }
                                ]
                            },
                            /* Transactions */
                            {
                                name: 'transactions',
                                path: 'transactions',
                                component: () => import('VenueTransaction/List.vue'),
                                meta: { label: () => App.$gettext('Transactions') },
                                children: [
                                    {
                                        name: 'transactions.edit',
                                        path: 'edit/:transactionId',
                                        component: () => import('VenueTransaction/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Transaction') }
                                    },
                                    {
                                        name: 'transactions.add',
                                        path: 'new',
                                        component: () => import('VenueTransaction/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Transaction') }
                                    }
                                ]
                            },
                            /* Official Transactions */
                            {
                                name: 'officialTransactions',
                                path: 'officialTransactions',
                                component: () => import('VenueTransaction/Officials/List.vue'),
                                meta: { label: () => App.$gettext('Transactions') },
                                children: [
                                    {
                                        name: 'officialTransactions.edit',
                                        path: 'edit/:transactionId',
                                        component: () => import('Official/tabs/TransactionDetail.vue'),
                                        meta: { label: () => App.$gettext('Edit Official Transaction') }
                                    },
                                    {
                                        name: 'officialTransactions.add',
                                        path: 'new',
                                        component: () => import('Official/tabs/TransactionDetail.vue'),
                                        meta: { label: () => App.$gettext('Add Official Transaction') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.transactionsForRider',
                                path: 'transactions/rider/:riderId',
                                component: () => import('VenueTransaction/List.vue'),
                                meta: { label: () => App.$gettext('Rider transactions') }
                            },
                            /* Organizer dashboard */
                            {
                                name: 'organizer',
                                path: 'organizer',
                                component: () => import('Organizer/List.vue'),
                                meta: { label: () => App.$gettext('Organizer view') }
                            },
                            /* Vet-check dashboard */
                            {
                                name: 'vetcheck',
                                path: 'vetcheck',
                                component: () => import('../VetCheck/VetCheckDashboard.vue'),
                                meta: { label: () => App.$gettext('Vet-check dashboard') }
                            },
                            /* Sign-In dashboard */
                            {
                                name: 'signin',
                                path: 'signin',
                                component: () => import('../SigninDashboard/SigninDashboard.vue'),
                                meta: { label: () => App.$gettext('Sign-In dashboard') }
                            },

                            /* Welcome desk */
                            {
                                name: 'admin.welcomedesk',
                                path: 'welcomedesk',
                                redirect: { name: 'admin.welcomedesk.list' },
                                component: { render: h => h('router-view') },
                                meta: { label: () => 'Attendees' },
                                children: [
                                    {
                                        name: 'admin.welcomedesk.list',
                                        path: 'list',
                                        component: () => import('WelcomeDesk/AttendeeList.vue'),
                                        meta: { label: () => 'Attendees' }

                                    },
                                    {
                                        name: 'admin.welcomedesk.add',
                                        path: 'add',
                                        component: () => import('WelcomeDesk/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add attendee') }
                                    }
                                ]
                            }

                        ]
                    },

                    /* VENUE ACTIONS */
                    {
                        name: 'admin.venues.actions',
                        path: ':venueId/actions',
                        component: VenueBaseComponent,
                        redirect: { name: 'actions.onboardingEmails' },
                        meta: { label: () => App.$gettext('Venue Views') },
                        children: [
                            /* Actions */
                            {
                                name: 'actions.onboardingEmails',
                                path: 'onboardingEmails',
                                meta: { label: () => App.$gettext('Onboarding emails') },
                                component: () => import('Venue/Actions/Onboarding.vue')
                            },
                            {
                                name: 'actions.invoices',
                                path: 'invoices',
                                meta: { label: () => App.$gettext('Invoices') },
                                component: () => import('Venue/Actions/Invoices.vue')
                            },
                            {
                                name: 'actions.uploadResults',
                                path: 'uploadResults',
                                meta: { label: () => App.$gettext('Upload XML results') },
                                component: () => import('Venue/Actions/UploadResults.vue')
                            },
                            {
                                name: 'actions.generateXMLResults',
                                path: 'generateXMLResults',
                                meta: { label: () => App.$gettext('Generate XML results') },
                                component: () => import('Venue/Actions/GenerateXMLResults.vue')
                            },
                            {
                                name: 'actions.sendEmails',
                                path: 'sendEmails',
                                meta: { label: () => App.$gettext('Send emails') },
                                component: () => import('Venue/Actions/SendEmails.vue')
                            },
                            {
                                name: 'actions.sendEmailsAttendees',
                                path: 'sendEmailsAttendees',
                                meta: { label: () => App.$gettext('Send emails to all attendees') },
                                component: () => import('Venue/Actions/SendEmailsAttendees.vue')
                            }
                        ]
                    }
                ]
            },

            /* GLOBAL SETTINGS */
            {
                name: 'admin.config',
                path: 'config',
                component: () => import('./tabs/Config.vue'),
                meta: { label: () => App.$gettext('Config') },
                redirect: { name: 'admin.config.countries' },
                children: [
                    /* Countries */
                    {
                        name: 'admin.config.countries',
                        path: 'countries',
                        component: () => import('./tabs/Countries.vue'),
                        meta: { label: () => App.$gettext('Countries') },
                        children: [
                            {
                                name: 'admin.config.countries.add',
                                path: 'add',
                                component: () => import('Country/Detail.vue'),
                                meta: { label: () => App.$gettext('Add new Country') }
                            },
                            {
                                name: 'admin.config.countries.edit',
                                path: 'edit/:id',
                                component: () => import('Country/Detail.vue'),
                                meta: { label: () => App.$gettext('Edit Country') }
                            }
                        ]
                    },

                    /* Currencies */
                    {
                        name: 'admin.config.currencies',
                        path: 'currencies',
                        component: () => import('./tabs/Currencies.vue'),
                        meta: { label: () => App.$gettext('Currencies') },
                        children: [
                            {
                                name: 'admin.config.currencies.add',
                                path: 'add',
                                component: () => import('Currency/Detail.vue'),
                                meta: { label: () => App.$gettext('Add new Currency') }
                            },
                            {
                                name: 'admin.config.currencies.edit',
                                path: 'edit/:id',
                                component: () => import('Currency/Detail.vue'),
                                meta: { label: () => App.$gettext('Edit Currency') }
                            }
                        ]
                    },

                    /* Event Categories */
                    {
                        name: 'admin.config.eventCategories',
                        path: 'eventCategories',
                        component: () => import('./tabs/EventCategories.vue'),
                        meta: { label: () => App.$gettext('Event Categories') },
                        children: [
                            {
                                name: 'admin.config.eventCategories.add',
                                path: 'add',
                                component: () => import('EventCategory/Detail.vue'),
                                meta: { label: () => App.$gettext('Add new Event Category') }
                            },
                            {
                                name: 'admin.config.eventCategories.edit',
                                path: 'edit/:id',
                                component: () => import('EventCategory/Detail.vue'),
                                meta: { label: () => App.$gettext('Edit Event Category') }
                            }
                        ]
                    },

                    /* Tax Types */
                    {
                        name: 'admin.config.taxTypes',
                        path: 'taxTypes',
                        component: () => import('./tabs/TaxTypes.vue'),
                        meta: { label: () => App.$gettext('Tax Types') },
                        children: [
                            {
                                name: 'admin.config.taxTypes.add',
                                path: 'add',
                                component: () => import('TaxType/Detail.vue'),
                                meta: { label: () => App.$gettext('Add new Tax Type') }
                            },
                            {
                                name: 'admin.config.taxTypes.edit',
                                path: 'edit/:id',
                                component: () => import('TaxType/Detail.vue'),
                                meta: { label: () => App.$gettext('Edit Tax Type') }
                            }
                        ]
                    },

                    {
                        name: 'admin.config.TC',
                        path: 'termsAndConditions',
                        component: () => import('AppConfiguration/TermsAndConditions.vue'),
                        meta: { label: () => App.$gettext('Terms and conditions') }
                    },

                    /* Organizer Bank accounts */
                    {
                        name: 'admin.config.organizerBankAccounts',
                        path: 'bankAccounts',
                        component: () => import('BankAccount/organizerList.vue'),
                        meta: { label: () => App.$gettext('Venue bank accounts') },
                        children: [
                            {
                                name: 'admin.config.organizerBankAccounts.add',
                                path: 'add',
                                component: () => import('BankAccount/organizerDetail.vue'),
                                meta: { label: () => App.$gettext('Add new venue bank account') }
                            },
                            {
                                name: 'admin.config.organizerBankAccounts.edit',
                                path: 'edit/:id',
                                component: () => import('BankAccount/organizerDetail.vue'),
                                meta: { label: () => App.$gettext('Edit venue bank account') }
                            }
                        ]
                    }
                ]
            },

            /* RIDERS */
            {
                name: 'admin.riders',
                path: 'riders',
                component: () => import('Admin/tabs/Riders.vue'),
                meta: { label: () => App.$gettext('Riders') },
                children: [
                    {
                        name: 'admin.rider.new',
                        path: 'add',
                        component: () => import('Rider/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Rider profile'), addNew: true }
                    },
                    {
                        name: 'admin.rider',
                        path: ':riderId',
                        component: () => import('Admin/RiderWrapper.vue'),
                        meta: { label: () => App.$gettext('Rider') },
                        redirect: { name: 'admin.rider.profile' },
                        children: [
                            {
                                name: 'admin.rider.profile',
                                path: 'profile',
                                component: () => import('Rider/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Rider profile') }
                            },
                            {
                                name: 'admin.rider.horses',
                                path: 'horses',
                                component: () => import('Rider/tabs/Horses.vue'),
                                meta: { label: () => App.$gettext('Horses') },
                                children: [
                                    {
                                        name: 'admin.rider.horses.add',
                                        path: 'add',
                                        component: () => import('Rider/tabs/Horses_Add.vue'),
                                        meta: { label: () => App.$gettext('Add a horse') }
                                    },
                                    {
                                        name: 'admin.rider.horses.view',
                                        path: 'view/:id',
                                        component: () => import('Rider/tabs/Horses_View.vue'),
                                        meta: { label: () => App.$gettext('Horse info') },
                                        children: [
                                            {
                                                name: 'admin.rider.horses.view.owner',
                                                path: ':horseOwnerId',
                                                component: () => import('HorseOwner/Detail.vue'),
                                                meta: { label: () => App.$gettext('Horse owner info') }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.grooms',
                                path: 'grooms',
                                component: () => import('Rider/tabs/Grooms.vue'),
                                meta: { label: () => App.$gettext('Grooms') },
                                children: [
                                    {
                                        name: 'admin.rider.grooms.add',
                                        path: 'add',
                                        component: () => import('Rider/tabs/Grooms_Add.vue'),
                                        meta: { label: () => App.$gettext('Add a groom') }
                                    },
                                    {
                                        name: 'admin.rider.grooms.edit',
                                        path: 'edit/:groomId',
                                        component: () => import('Rider/tabs/Grooms_Edit.vue'),
                                        meta: { label: () => App.$gettext('Edit a groom') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'admin.rider.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'admin.rider.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    },
                                    {
                                        name: 'admin.rider.bankAccounts.add_svps',
                                        path: 'add_svps',
                                        component: () => import('BankAccount/DetailSvps.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account at SVPS') }
                                    },
                                    {
                                        name: 'admin.rider.bankAccounts.edit_svps',
                                        path: 'svps/:bankAccountId',
                                        component: () => import('BankAccount/DetailSvps.vue'),
                                        meta: { label: () => App.$gettext('Edit a SVPS bank account') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.documents',
                                path: 'documents',
                                component: () => import('Documents/List.vue'),
                                meta: { label: () => App.$gettext('Documents') },
                                children: [
                                    {
                                        name: 'admin.rider.documents.add',
                                        path: 'add',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a document') }
                                    },
                                    {
                                        name: 'admin.rider.documents.edit',
                                        path: ':id',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a document') }
                                    }
                                ]
                            },
                            // {
                            //     name: 'admin.rider.venueTransactions',
                            //     path: 'venue/:venueId/venueTransactions',
                            //     component: () => import('VenueTransaction/List.vue'),
                            //     meta: { label: () => App.$gettext('Rider venue transactions') }
                            // },
                            {
                                name: 'admin.rider.venueTransactions',
                                path: 'venue/:venueId/transactions',
                                component: () => import('VenueTransaction/List.vue'),
                                meta: { label: () => App.$gettext('Rider venue transactions') },
                                children: [
                                    {
                                        name: 'admin.rider.venueTransactions.edit',
                                        path: 'edit/:transactionId',
                                        component: () => import('VenueTransaction/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit Transaction') }
                                    },
                                    {
                                        name: 'admin.rider.venueTransactions.add',
                                        path: 'new',
                                        component: () => import('VenueTransaction/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add Transaction') }
                                    }
                                ]
                            },

                            {
                                name: 'admin.rider.VenueParticipations',
                                path: 'venue/:venueId/VenueParticipations',
                                component: () => import('VenueEventCategoryParticipation/ListForRider.vue'),
                                meta: { label: () => App.$gettext('Rider venue participations') },
                                children: [
                                    {
                                        name: 'admin.rider.VenueParticipations.add',
                                        path: 'add',
                                        component: () => import('VenueParticipation/EditVenueParticipation.vue'),
                                        meta: { label: () => App.$gettext('Edit Venue Participation') }
                                    },
                                    {
                                        name: 'admin.rider.VenueParticipations.edit',
                                        path: ':participationId(\\d+)',
                                        component: () => import('VenueParticipation/EditVenueParticipation.vue'),
                                        meta: { label: () => App.$gettext('Edit Venue Participation') },
                                        children: [
                                            {
                                                name: 'admin.rider.VenueParticipations.addExternalCost',
                                                path: 'externalCost/add',
                                                component: () => import('VenueParticipation/ExternalCostDetail.vue'),
                                                meta: {
                                                    label: () =>
                                                        App.$gettext('Add Venue Participation External Cost')
                                                }
                                            },
                                            {
                                                name: 'admin.rider.VenueParticipations.editExternalCost',
                                                path: 'externalCost/:externalCostID',
                                                component: () => import('VenueParticipation/ExternalCostDetail.vue'),
                                                meta: {
                                                    label: () =>
                                                        App.$gettext('Edit Venue Participation External Cost')
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.VECParticipations',
                                path: 'venue/:venueId/VECParticipations',
                                component: () => import('VenueEventCategoryParticipation/ListForRider.vue'),
                                meta: { label: () => App.$gettext('Rider VEC participations') },
                                children: [
                                    {
                                        name: 'admin.rider.VECParticipations.add',
                                        path: 'add',
                                        component: () =>
                                            import('VenueEventCategoryParticipation/HorseParticipationDetail.vue'),
                                        meta: { label: () => App.$gettext('Add a VEC participation') }
                                    },
                                    {
                                        name: 'admin.rider.VECParticipations.edit',
                                        path: ':participationId',
                                        component: () =>
                                            import('VenueEventCategoryParticipation/HorseParticipationDetail.vue'),
                                        meta: { label: () => App.$gettext('Edit a VEC participation') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.employment',
                                path: 'employment',
                                component: () => import('Rider/tabs/Employment.vue'),
                                meta: { label: () => App.$gettext('Employment') }
                            },
                            {
                                name: 'admin.rider.teamMembers',
                                path: 'teamMembers',
                                component: () => import('TeamMember/List.vue'),
                                meta: { label: () => App.$gettext('Accreditation') },
                                children: [
                                    {
                                        name: 'admin.rider.teamMembers.add',
                                        path: 'add',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a team member') }
                                    },
                                    {
                                        name: 'admin.rider.teamMembers.edit',
                                        path: ':teamMembersId',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a team member') }
                                    },
                                    {
                                        name: 'admin.rider.teamMembers.join',
                                        path: 'join',
                                        component: () => import('TeamMember/JoinTeam.vue'),
                                        meta: { label: () => App.$gettext('Join a team') }
                                    },
                                    {
                                        name: 'admin.rider.teamMembers.documents',
                                        path: 'documents',
                                        component: { render: h => h('router-view') },
                                        redirect: { name: 'admin.rider.teamMembers' },
                                        meta: { label: () => App.$gettext('Documents') },
                                        children: [
                                            {
                                                name: 'admin.rider.teamMembers.documents.add',
                                                path: 'add',
                                                component: () => import('Documents/Detail.vue'),
                                                meta: { label: () => App.$gettext('Add a document') },
                                                props: true
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.reports',
                                path: 'reports',
                                component: () => import('Reports/Reports.vue'),
                                meta: { label: () => App.$gettext('Reports') }
                            },
                            {
                                name: 'admin.rider.balance',
                                path: 'balance',
                                component: () => import('Balance/List.vue'),
                                meta: { label: () => App.$gettext('Balance') },
                                children: [
                                    {
                                        name: 'admin.rider.balance.add',
                                        path: 'add',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a rider balance transaction') }
                                    },
                                    {
                                        name: 'admin.rider.balance.edit',
                                        path: ':balanceId',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a rider balancetransaction') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.rider.creditCard',
                                path: 'creditCard',
                                component: () => import('Rider/tabs/CreditCard.vue'),
                                meta: { label: () => App.$gettext('Credit Card') }
                            },
                            // Shows list
                            {
                                name: 'admin.rider.venues',
                                path: 'shows',
                                component: () => import('Rider/tabs/Shows.vue'),
                                meta: { label: () => App.$gettext('Shows') }
                            }
                        ]
                    }
                ]
            },
            {
                name: 'admin.employers',
                path: 'employers',
                component: () => import('Admin/tabs/Employers.vue'),
                meta: { label: () => App.$gettext('Employers') },
                children: [
                    {
                        name: 'admin.employers.add',
                        path: 'add',
                        component: () => import('Employer/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Add Employer') }
                    },
                    {
                        name: 'admin.employer',
                        path: ':employerId',
                        component: () => import('Admin/EmployerWrapper.vue'),
                        meta: { label: () => App.$gettext('Employer') },
                        redirect: { name: 'admin.employer.profile' },
                        children: [
                            {
                                name: 'admin.employer.profile',
                                path: 'profile',
                                component: () => import('Employer/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Employer profile') }
                            },
                            {
                                name: 'admin.employer.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'admin.employer.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'admin.employer.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    },
                                    {
                                        name: 'admin.employer.bankAccounts.add_svps',
                                        path: 'add_svps',
                                        component: () => import('BankAccount/DetailSvps.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account at SVPS') }
                                    },
                                    {
                                        name: 'admin.employer.bankAccounts.edit_svps',
                                        path: 'svps/:bankAccountId',
                                        component: () => import('BankAccount/DetailSvps.vue'),
                                        meta: { label: () => App.$gettext('Edit a SVPS bank account') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.employer.employees',
                                path: 'employees',
                                component: () => import('Employer/tabs/Employees.vue'),
                                meta: { label: () => App.$gettext('Employees') },
                                children: [
                                    {
                                        name: 'admin.employer.employees.add',
                                        path: 'add',
                                        component: () => import('Employer/tabs/Employees_Add.vue'),
                                        meta: { label: () => App.$gettext('Add an employee') }
                                    }
                                ]
                            },

                            // Team Members
                            {
                                name: 'admin.employer.teamMembers',
                                path: 'teamMembers',
                                component: () => import('TeamMember/List.vue'),
                                meta: { label: () => App.$gettext('Accreditation') },
                                children: [
                                    {
                                        name: 'admin.employer.teamMembers.join',
                                        path: 'join',
                                        component: () => import('TeamMember/JoinTeam.vue'),
                                        meta: { label: () => App.$gettext('Join a team') }
                                    }

                                ]
                            },

                            {
                                name: 'admin.employer.documents',
                                path: 'documents',
                                component: () => import('Documents/List.vue'),
                                meta: { label: () => App.$gettext('Documents') },
                                children: [
                                    {
                                        name: 'admin.employer.documents.add',
                                        path: 'add',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a document') }
                                    },
                                    {
                                        name: 'admin.employer.documents.edit',
                                        path: ':id',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a document') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.employer.reports',
                                path: 'reports',
                                component: () => import('Reports/Reports.vue'),
                                meta: { label: () => App.$gettext('Reports') }
                            },
                            {
                                name: 'admin.employer.balance',
                                path: 'balance',
                                component: () => import('Balance/List.vue'),
                                meta: { label: () => App.$gettext('Balance') },
                                children: [
                                    {
                                        name: 'admin.employer.balance.add',
                                        path: 'add',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add an employer balance transaction') }
                                    },
                                    {
                                        name: 'admin.employer.balance.edit',
                                        path: ':balanceId',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit an employer balancetransaction') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.employer.creditCard',
                                path: 'creditCard',
                                component: () => import('Rider/tabs/CreditCard.vue'),
                                meta: { label: () => App.$gettext('Credit Card') }
                            }
                        ]
                    }
                ]
            },
            // Officials
            {
                name: 'admin.officials',
                path: 'officials',
                component: () => import('Admin/tabs/Officials.vue'),
                meta: { label: () => App.$gettext('Officials') },
                children: [
                    {
                        name: 'admin.officials.new',
                        path: 'add',
                        component: () => import('Official/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Add an Official'), addNew: true }
                    },
                    {
                        name: 'admin.official',
                        path: ':officialId',
                        component: () => import('Admin/MerchantWrapper.vue'),
                        meta: { label: () => App.$gettext('Official') },
                        redirect: { name: 'admin.official.profile' },
                        children: [
                            {
                                name: 'admin.official.profile',
                                path: 'profile',
                                component: () => import('Official/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Official profile') }
                            },
                            {
                                name: 'admin.official.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'admin.official.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'admin.official.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.official.documents',
                                path: 'documents',
                                component: () => import('Documents/List.vue'),
                                meta: { label: () => App.$gettext('Documents') },
                                children: [
                                    {
                                        name: 'admin.official.documents.add',
                                        path: 'add',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a document') }
                                    },
                                    {
                                        name: 'admin.official.documents.edit',
                                        path: ':id',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a document') }
                                    }
                                ]
                            },

                            // Team Members
                            {
                                name: 'admin.official.teamMembers',
                                path: 'teamMembers',
                                component: () => import('TeamMember/List.vue'),
                                meta: { label: () => App.$gettext('Accreditation') },
                                children: [
                                    {
                                        name: 'admin.official.teamMembers.join',
                                        path: 'join',
                                        component: () => import('TeamMember/JoinTeam.vue'),
                                        meta: { label: () => App.$gettext('Join a team') }
                                    }

                                ]
                            },

                            {
                                name: 'admin.official.venues',
                                path: 'venues',
                                component: () => import('Official/tabs/Venues.vue'),
                                meta: { label: () => App.$gettext('Venues') },
                                children: [
                                    {
                                        name: 'admin.official.venues.add',
                                        path: 'add',
                                        component: () => import('Official/tabs/AddParticipation.vue'),
                                        meta: { label: () => App.$gettext('Add Participation') }
                                    },
                                    {
                                        name: 'admin.official.venues.transactions',
                                        path: ':venueId/transactions',
                                        component: () => import('Official/tabs/TransactionsAtVenue.vue'),
                                        meta: { label: () => App.$gettext('Transactions') }
                                    },
                                    {
                                        name: 'admin.official.venues.transactions.add',
                                        path: ':venueId/transactions/add',
                                        component: () => import('Official/tabs/TransactionDetail.vue'),
                                        meta: { label: () => App.$gettext('Transactions') }
                                    },
                                    {
                                        name: 'admin.official.venues.transactions.edit',
                                        path: ':venueId/transactions/:transactionId',
                                        component: () => import('Official/tabs/TransactionDetail.vue'),
                                        meta: { label: () => App.$gettext('Transactions') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.official.reports',
                                path: 'reports',
                                component: () => import('Reports/Reports.vue'),
                                meta: { label: () => App.$gettext('Reports') }
                            },
                            {
                                name: 'admin.official.balance',
                                path: 'balance',
                                component: () => import('Balance/List.vue'),
                                meta: { label: () => App.$gettext('Balance') },
                                children: [
                                    {
                                        name: 'admin.official.balance.add',
                                        path: 'add',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add an official balance transaction') }
                                    },
                                    {
                                        name: 'admin.official.balance.edit',
                                        path: ':balanceId',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit an official balancetransaction') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.official.creditCard',
                                path: 'creditCard',
                                component: () => import('Rider/tabs/CreditCard.vue'),
                                meta: { label: () => App.$gettext('Credit Card') }
                            }
                        ]
                    }
                ]
            },
            // Merchants
            {
                name: 'admin.merchants',
                path: 'merchants',
                component: () => import('Admin/tabs/Merchants.vue'),
                meta: { label: () => App.$gettext('Merchants') },
                children: [
                    {
                        name: 'admin.merchants.add',
                        path: 'add',
                        component: () => import('Merchant/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Add an Merchant') }
                    },
                    {
                        name: 'admin.merchant',
                        path: ':merchantId',
                        component: () => import('Admin/MerchantWrapper.vue'),
                        meta: { label: () => App.$gettext('Merchant') },
                        redirect: { name: 'admin.merchant.profile' },
                        children: [
                            {
                                name: 'admin.merchant.profile',
                                path: 'profile',
                                component: () => import('Merchant/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Merchant profile') }
                            },
                            {
                                name: 'admin.merchant.addTransaction',
                                path: 'addTransaction',
                                component: () => import('Merchant/AddTransactionAdmin.vue'),
                                meta: { label: () => App.$gettext('Add transaction') }
                            },

                            {
                                name: 'admin.merchant.transactions',
                                path: 'listTransactions',
                                component: () => import('MerchantBalance/List.vue'),
                                meta: { label: () => App.$gettext('Merchant transactions') }
                            },
                            {
                                name: 'admin.merchant.evclFees',
                                path: 'evclFees',
                                component: () => import('Merchant/tabs/Fees.vue'),
                                meta: { label: () => App.$gettext('Merchant processing fees') },
                                children: [
                                    {
                                        name: 'admin.merchant.evclFees.add',
                                        path: 'add',
                                        component: () => import('Merchant/tabs/Fees_Add.vue'),
                                        meta: { label: () => App.$gettext('Add a processing fee') }
                                    }

                                ]
                            },

                            {
                                name: 'admin.merchant.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'admin.merchant.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'admin.merchant.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    }
                                ]
                            }

                        ]
                    }
                ]
            },

            {
                name: 'admin.balances',
                path: 'balances',
                component: () => import('Admin/tabs/Balances.vue'),
                meta: { label: () => App.$gettext('Balances') },
                redirect: { name: 'admin.balances.overview' },
                children: [
                    {
                        name: 'admin.balances.overview',
                        path: 'overview',
                        component: () => import('Balance/Overview.vue'),
                        meta: { label: () => App.$gettext('Balances overview') }
                    },
                    {
                        name: 'admin.balances.summary',
                        path: 'summary',
                        component: () => import('Balance/Summary.vue'),
                        meta: { label: () => App.$gettext('Balances Summary') }
                    }
                ]
            },
            {
                name: 'admin.findClient',
                path: 'findClient',
                component: () => import('Admin/tabs/FindClient.vue'),
                meta: { label: () => App.$gettext('Balances') }
            },

            /* Consumables */
            {
                name: 'admin.consumables',
                path: 'consumables',
                redirect: { name: 'admin.consumables.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'admin.consumables.list',
                        path: 'list',
                        component: () => import('Consumable/List.vue'),
                        meta: { label: () => App.$gettext('Consumables') }
                    },
                    {
                        name: 'admin.consumables.add',
                        path: 'add',
                        component: () => import('Consumable/Detail.vue'),
                        meta: { label: () => App.$gettext('Add new Consumable') }
                    },
                    {
                        name: 'admin.consumables.edit',
                        path: 'edit/:id',
                        component: () => import('Consumable/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit Consumable') }
                    }
                ]
            },
            /* Devices */

            {
                name: 'admin.devices',
                path: 'devices',
                redirect: { name: 'admin.devices.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'admin.devices.list',
                        path: 'list',
                        component: () => import('Device/List.vue'),
                        meta: { label: () => App.$gettext('Devices') }
                    },
                    {
                        name: 'admin.devices.add',
                        path: 'add',
                        component: () => import('Device/Detail.vue'),
                        meta: { label: () => App.$gettext('Add new Device') }
                    },
                    {
                        name: 'admin.devices.edit',
                        path: 'edit/:id',
                        component: () => import('Device/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit Device') }
                    }
                ]
            },
            /* Duplicates */

            {
                name: 'admin.duplicates',
                path: 'duplicates',
                component: () => import('Admin/tabs/Duplicates.vue'),
                meta: { label: () => App.$gettext('Duplicates') },
                redirect: { name: 'admin.duplicates.duplicateHorses' },
                children: [
                    {
                        name: 'admin.duplicates.duplicateHorses',
                        path: 'duplicateHorses',
                        component: () => import('DuplicateHorses/List.vue'),
                        meta: { label: () => App.$gettext('Duplicate horses') },
                        children: [
                            {
                                name: 'admin.duplicates.duplicateHorses.edit',
                                path: 'edit/:id',
                                component: () => import('DuplicateHorses/Detail.vue'),
                                meta: {
                                    label: () =>
                                        App.$gettext('Resolve duplicate case')
                                }
                            }
                        ]
                    }

                ]
            },
            // Simple users
            {
                name: 'admin.simpleusers',
                path: 'simpleusers',
                component: () => import('Admin/tabs/SimpleUsers.vue'),
                meta: { label: () => App.$gettext('Simple Users') },
                children: [
                    {
                        name: 'admin.simpleuser.add',
                        path: 'add',
                        component: () => import('SimpleUser/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Add an Simple User') }
                    },
                    {
                        name: 'admin.simpleuser',
                        path: ':simpleUserId',
                        component: () => import('Admin/SimpleUserWrapper.vue'),
                        meta: { label: () => App.$gettext('Simple User') },
                        redirect: { name: 'admin.simpleuser.profile' },
                        children: [
                            {
                                name: 'admin.simpleuser.profile',
                                path: 'profile',
                                component: () => import('SimpleUser/tabs/Profile.vue'),
                                meta: { label: () => App.$gettext('Simple User profile') }
                            },
                            {
                                name: 'admin.simpleuser.bankAccounts',
                                path: 'bankAccounts',
                                component: () => import('BankAccount/List.vue'),
                                meta: { label: () => App.$gettext('Bank accounts') },
                                children: [
                                    {
                                        name: 'admin.simpleuser.bankAccounts.add',
                                        path: 'add',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a bank account') }
                                    },
                                    {
                                        name: 'admin.simpleuser.bankAccounts.edit',
                                        path: ':bankAccountId',
                                        component: () => import('BankAccount/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a bank account') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.simpleuser.documents',
                                path: 'documents',
                                component: () => import('Documents/List.vue'),
                                meta: { label: () => App.$gettext('Documents') },
                                children: [
                                    {
                                        name: 'admin.simpleuser.documents.add',
                                        path: 'add',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a document') }
                                    },
                                    {
                                        name: 'admin.simpleuser.documents.edit',
                                        path: ':id',
                                        component: () => import('Documents/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a document') }
                                    }
                                ]
                            },

                            // Team Members
                            {
                                name: 'admin.simpleuser.teamMembers',
                                path: 'teamMembers',
                                component: () => import('TeamMember/List.vue'),
                                meta: { label: () => App.$gettext('Accreditation') },
                                children: [
                                    {
                                        name: 'admin.simpleuser.teamMembers.join',
                                        path: 'join',
                                        component: () => import('TeamMember/JoinTeam.vue'),
                                        meta: { label: () => App.$gettext('Join a team') }
                                    },
                                    {
                                        name: 'admin.simpleuser.teamMembers.add',
                                        path: 'add',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add a team member') },
                                        props: true
                                    },
                                    {
                                        name: 'admin.simpleuser.teamMembers.edit',
                                        path: ':teamMembersId',
                                        component: () => import('TeamMember/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit a team member') }
                                    }

                                ]
                            },
                            {
                                name: 'admin.simpleuser.balance',
                                path: 'balance',
                                component: () => import('Balance/List.vue'),
                                meta: { label: () => App.$gettext('Balance') },
                                children: [
                                    {
                                        name: 'admin.simpleuser.balance.add',
                                        path: 'add',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Add an simple user balance transaction') }
                                    },
                                    {
                                        name: 'admin.simpleuser.balance.edit',
                                        path: ':balanceId',
                                        component: () => import('Balance/Detail.vue'),
                                        meta: { label: () => App.$gettext('Edit an simple user balancetransaction') }
                                    }
                                ]
                            },
                            {
                                name: 'admin.simpleuser.creditCard',
                                path: 'creditCard',
                                component: () => import('Rider/tabs/CreditCard.vue'),
                                meta: { label: () => App.$gettext('Credit Card') }
                            }
                        ]
                    }
                ]
            }

        ]
    }
]
