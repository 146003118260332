<template>
    <div id="app" @click="closeHamburgerIfOpen($event)">
        <div id="env" :class="env" />
        <UnimpersonateButton />
        <Spinner />
        <Toast ref="toast" />
        <tooltip :width="-1" />
        <vue-confirm-dialog></vue-confirm-dialog>

        <div v-if="updateIsAvailable && !updateIsAvailableHidden" id="update-available" class="notification is-warning is-light">
            <button class="delete" @click="updateIsAvailableHidden = true" />
            <div class="is-flex	is-flex-wrapwrap is-justify-content-center is-flex-direction-column	">
                <div v-translate class="mb-2">Application has been updated. Please, reload the page.</div>
                <div style="flex-basis: 100%; height: 0" />
                <a v-translate class="button is-rounded" @click.prevent.stop="reloadApp">Click to reload</a>
            </div>
        </div>
        <section class="hero">
            <div class="hero-body">
                <div class="container is-fluid">
                    <div class="level page-header">
                        <div class="level-left">
                            <div class="level-item">
                                <router-link v-if="userRoles.length > 1" class="title"
                                             :to="{ name: 'chooseDashboard' }">
                                    <img :src="logo" alt="EventClearing">
                                </router-link>
                                <router-link v-else
                                             class="title"
                                             :to="(userRoles && userRoles[0]) ? { name: `${userRoles[0]}.dashboard` } : { path: '/' }">
                                    <img :src="logo" alt="EventClearing">
                                </router-link>
                            </div>
                        </div>
                        <div class="level-right is-hidden-mobile">
                            <div v-if="$route.name !== 'login'" class="level-item">
                                <translate>Language</translate>
                                :&nbsp;
                                <LanguageSwitcher />
                            </div>
                            <div v-if="$auth.check()" class="level-item">
                                <a @click.prevent="logout()">
                                    <translate>Logout</translate>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <router-view />

        <section class="footer">
            <div class="container">
                <small v-once>
                    v{{ version }} - © {{ new Date().getFullYear() }} EventClearing S.A.
                </small>
            </div>
        </section>
    </div>
</template>

<style lang="stylus" scoped>
    @require '~styles/$vars.styl'

    .title > img
        height 50px

    #update-available
        z-index 99
        position: absolute;
        top: 10px;
        right: 10px;
</style>

<script>
    import packageJson from '../../package.json'
    import Spinner from 'common/Spinner.vue'
    import Toast from 'vue-toast'
    import Vue from 'vue'
    import LanguageSwitcher from 'common/LanguageSwitcher.vue'
    import UnimpersonateButton from 'Auth/UnimpersonateButton.vue'
    import { mapGetters, mapMutations } from 'vuex'
    import evclLogo from 'common/logo.png'
    import Axios from 'axios'

    const version = packageJson.version

    export default {
        components: {
            Spinner,
            Toast,
            LanguageSwitcher,
            UnimpersonateButton
        },

        data: () => ({
            version,
            env: __STAGING__ ? 'staging' : __PROD__ ? 'production' : 'local',
            logo_url: null,
            venue: null,
            updateIsAvailableHidden: false
        }),

        computed: {
            ...mapGetters([ 'hamburgerOpen', 'activeVenue', 'updateIsAvailable' ]),
            userRoles() {
                return this.$auth.user()?.roles ?? []
            },
            logo() {
                if (this.$auth.user() && this.$auth.user().roles && this.$auth.user().roles.includes('organizer') && this.activeVenue && this.activeVenue.whitelabel_logo_url)
                    return this.activeVenue.whitelabel_logo_url
                else if (this.logo_url)
                    return this.logo_url
                return evclLogo
            }
        },

        watch: {
            hamburgerOpen(isOpen) {
                document.documentElement.classList.toggle('hamburgerOpen', isOpen)
            }
        },

        mounted() {
            this.$refs.toast.options.position = 'top right'
            Vue.prototype.toast = this.$refs.toast
            Vue.prototype.notify = (msg, klass) => {
                const defaults = {
                    timeLife: [ 'success', 'info' ].includes(klass) ? 5000 : 10000,
                    theme: klass
                }
                this.$refs.toast.showToast(msg, defaults)
            }

            // document.documentElement.classList.toggle('hamburgerOpen', this.hamburgerOpen)
            this.closeHamburgerIfOpen()

            Axios
                .get('/api/v1/venue/check_hostname/')
                .then(data => {

                    this.$store.commit('setDomainIsWhitelabeled', !!data)
                    if (data) {

                        this.logo_url = data.logo
                        this.$root.whitelabelVenueId = data.venue
                    }
                })
        },

        methods: {
            ...mapMutations([ 'toggleHamburger' ]),
            logout() {
                this.$auth.logout()
                this.$router.go('/')
            },
            closeHamburgerIfOpen(event) {
                if (event && event.path) {
                    // only if clicked outside of navigation
                    const clickedInsideNav = event.path.map(el =>
                        el.className !== undefined && el.className.indexOf('nav') !== -1
                    )
                    if (clickedInsideNav)
                        return
                }

                if (this.hamburgerOpen)
                    this.toggleHamburger()
            },
            reloadApp() {
                window.location.reload()
            }
        }
    }
</script>
