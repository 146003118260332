import { setDashboard } from 'Auth/service'
import { App } from 'index'

export default [
    {
        name: 'simpleuser.dashboard',
        path: '/simpleuser/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'simpleuser.overview' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: [ 'simpleuser' ]
        },
        beforeEnter(to, from, next) {
            setDashboard('simpleuser')
            next()
        },
        children: [
            {
                name: 'simpleuser.overview',
                path: 'overview',
                component: () => import('./Overview/Overview.vue'),
                meta: { label: () => App.$gettext('User overview') }

            },
            {
                name: 'simpleuser.profile',
                path: 'profile',
                component: () => import('./tabs/Profile.vue'),
                meta: { label: () => App.$gettext('User profile') }
            },
            {
                name: 'simpleuser.bankAccounts',
                path: 'bankAccounts',
                component: () => import('BankAccount/List.vue'),
                meta: { label: () => App.$gettext('Bank accounts') },
                children: [
                    {
                        name: 'simpleuser.bankAccounts.add',
                        path: 'add',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a bank account') }
                    },
                    {
                        name: 'simpleuser.bankAccounts.edit',
                        path: ':bankAccountId',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a bank account') }
                    }
                ]
            },
            {
                name: 'simpleuser.documents',
                path: 'documents',
                component: () => import('Documents/List.vue'),
                meta: { label: () => App.$gettext('Documents') },
                children: [
                    {
                        name: 'simpleuser.documents.add',
                        path: 'add',
                        component: () => import('Documents/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a document') }
                    }
                ]
            },
            {
                name: 'simpleuser.balance',
                path: 'balance',
                component: () => import('Balance/List.vue'),
                meta: { label: () => App.$gettext('Balance') }
            },
            {
                name: 'simpleuser.creditCard',
                path: 'creditCard',
                component: () => import('./tabs/CreditCard.vue'),
                meta: { label: () => App.$gettext('Credit Card') }
            },
            {
                name: 'simpleuser.createPayment',
                path: 'createPayment',
                component: () => import('UpfrontPayments/CreatePayment.vue'),
                meta: { label: () => App.$gettext('Create payment'), whitelabelSafe: true }
            },
            {
                name: 'simpleuser.teamMembers',
                path: 'teamMembers',
                component: () => import('TeamMember/List.vue'),
                meta: { label: () => App.$gettext('Accreditation') },
                children: [
                    {
                        name: 'simpleuser.teamMembers.join',
                        path: 'join',
                        component: () => import('TeamMember/JoinTeam.vue'),
                        meta: { label: () => App.$gettext('Join a team') }
                    },
                    {
                        name: 'simpleuser.teamMembers.add',
                        path: 'add',
                        component: () => import('TeamMember/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a team member') },
                        props: true
                    },
                    {
                        name: 'simpleuser.teamMembers.edit',
                        path: ':teamMembersId',
                        component: () => import('TeamMember/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a team member') }
                    }

                ]


            }
        ]
    }
]
