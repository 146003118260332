export const API_URL = '/api/v1'
export const GRAPHQL_URL = '/api/graphql'
export const STRIPE_KEY = (__DEV__ || __STAGING__)
    ? 'pk_test_1Nd2N0IUDnYEHbmdHLVm0Y63'
    : 'pk_live_SHxRRMTcN7qielwplfUrmdQV'
export const PAGINATION = { pageSize: 100 }

export const CKEDITOR_SRC = '//cdn.ckeditor.com/4.7.3/full/ckeditor.js'
export const EDITOR_CONFIG = {
    toolbar: [
        [ 'Bold',
            'Italic',
            'Underline',
            'Strike',
            'Subscript',
            'Superscript',
            'Source' ],
        {
            name: 'clipboard',
            items: [ 'Cut',
                'Copy',
                'Paste',
                'PasteText',
                'PasteFromWord',
                '-',
                'Undo',
                'Redo' ]
        },
        {
            name: 'insert',
            items: [ 'Table' ]
        },
        {
            name: 'links',
            items: [ 'Link', 'Unlink', 'Anchor' ]
        },
        {
            name: 'styles',
            items: [ 'Format' ]
        }
    ],
    height: 350
}
export const EDITOR_WITHOUT_STYLES_CONFIG = (() => {
    const config = Object.assign({}, EDITOR_CONFIG)
    config.toolbar.pop()
    return config
})()
