// Create VueI18n instance with options
import GetTextPlugin from 'vue-gettext'
import { Axios } from './config'
import { App } from './index'
import store from './store'

export default (Vue) => {
    Vue.use(GetTextPlugin, {
        availableLanguages: {
            en: 'English',
            de: 'Deutsch',
            pl: 'Polski',
            fr: 'Français',
            it: 'Italian'
        },
        translations: {},
        defaultLanguage: store.getters.language,
        silent: true
    })

    return new Promise((resolve, reject) => {
        const params = { t: new Date().getTime() }
        const axios = Axios.create({})

        const request = __DEVSERVER__
            ? import('locale/translations.json').then(data => {
                return { data }
            })
            : axios.get('/static/dist/translations.json', { params })

        request
            .then(resolve)
            .catch(reject)

    }).then(translations => {
        Object.keys(translations.data).forEach(langKey => {
            App.$set(Vue.$translations, langKey, translations.data[langKey])
        })
        App.$set(App.$language, 'current', store.getters.language)
    })
}
