import { setDashboard } from 'Auth/service'
import { App } from 'index'


export default [
    {
        name: 'merchant.dashboard',
        path: '/merchant/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'merchant.overview' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: 'merchant'
        },
        beforeEnter(to, from, next) {
            setDashboard('merchant')
            next()
        },
        children: [
            {
                name: 'merchant.overview',
                path: 'dashboard',
                component: () => import('./Overview.vue'),
                meta: { label: () => App.$gettext('Overview') }
            },
            {
                name: 'merchant.addTransaction',
                path: 'addTransaction',
                component: () => import('./AddTransaction.vue'),
                meta: { label: () => App.$gettext('Add transaction') }
            },
            {
                name: 'merchant.transactions',
                path: 'listTransactions',
                component: () => import('MerchantBalance/List.vue'),
                meta: { label: () => App.$gettext('Merchant transactions') }
            },
            {
                name: 'merchant.profile',
                path: 'profile',
                component: () => import('./tabs/Profile.vue'),
                meta: { label: () => App.$gettext('Merchant profile') }
            },
            {
                name: 'merchant.bankAccounts',
                path: 'bankAccounts',
                component: () => import('BankAccount/List.vue'),
                meta: { label: () => App.$gettext('Bank accounts') },

                children: [
                    {
                        name: 'merchant.bankAccounts.add',
                        path: 'add',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Add a bank account') }
                    },
                    {
                        name: 'merchant.bankAccounts.edit',
                        path: ':bankAccountId',
                        component: () => import('BankAccount/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit a bank account') }
                    }
                ]
            },
            {
                name: 'merchant.evclFees',
                path: 'evclFees',
                component: () => import('Merchant/tabs/Fees.vue'),
                meta: { label: () => App.$gettext('Processing fees ') }

            }

        ]

    }
]
