import { setDashboard } from 'Auth/service'
import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { App } from 'index'

export default [
    {
        name: 'stable.dashboard',
        path: '/stable',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'stable.overview' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: 'stable'
        },
        beforeEnter(to, from, next) {
            setDashboard('stable')
            const assignedVenueId = App.$auth.user().assigned_venue
            if (!assignedVenueId) {
                App.notify('User doesn\'t have an assigned venue!', 'danger')
                next(false)
                return
            }

            VenueService.get(assignedVenueId).then((venue) => {
                setVenue(venue)
                next()
            }).catch((err) => {
                console.error(err)
                unsetVenue()
                next(err)
                throw err
            })
        },
        children: [
            {
                name: 'stable.overview',
                path: 'dashboard',
                component: () => import('./Overview.vue'),
                meta: { label: ()=> App.$gettext('Overview') }
            },
            {
                name: 'stable.addTransaction',
                path: 'addTransaction',
                component: () => import('./AddTransaction.vue'),
                meta: { label: ()=> App.$gettext('Add transaction') }
            },
            {
                name: 'stable.venue.transactions',
                path: 'transactions',
                component: () => import('VenueTransaction/List.vue'),
                meta: { label: ()=> App.$gettext('Venue transactions') }
            },
            {
                name: 'stable.qrRedirect',
                path: 'qr/:userType([gr]):userId(\\d+)-:hash',
                component: () => import('./QRRedirect.vue'),
                meta: { label: ()=> App.$gettext('Add transaction') }
            }
        ]
    }
]
