import RiderService from 'Rider/service'
import OfficialService from 'Official/service'
import MerchantService from 'Merchant/service'
import EmployerService from 'Employer/service'
import SimpleUserService from 'SimpleUser/service'
function getUserType(user) {
    return user.is_rider ? 'rider'
        : user.is_employer ? 'employer'
            : user.is_official ? 'official'
                : user.is_merchant ? 'merchant'
                    : user.is_simple_user ? 'simple_user'
                        : '!!UNKNOWN!!'
}

function logUser(prefix, user, suffix = '') {
    __DEV__ && console.info(`%c${prefix}: %c#${user.id} %c${getUserType(user)} %c${user.displayName}`,
        'color: #888; font-style:italic', 'font-size:0.85em;', 'color: #48C; font-weight:bold', '', suffix)
}

export default {
    install(Vue, { store }) {

        Vue.router.beforeResolve((to, from, next) => {

            const riderId = to.params.riderId ? to.params.riderId : null
            const employerId = to.params.employerId ? to.params.employerId : null
            const officialId = to.params.officialId ? to.params.officialId : null
            const simpleUserId = to.params.simpleUserId ? to.params.simpleUserId : null

            const merchantId = to.params.merchantId ? to.params.merchantId : null

            const userId = riderId || employerId || officialId || merchantId || simpleUserId

            if (store.getters.routeUser !== null && userId === null) {
                __DEV__ && console.info('%cresetRouteUser', 'color: #888; font-style:italic')
                store.commit('resetRouteUser')
            }

            if (store.getters.routeUser && parseInt(store.getters.routeUser.id) === parseInt(userId)) {
                logUser('RouteUser unchanged', store.getters.routeUser)
                next()
                return
            }

            let promise
            if (riderId)
                promise = RiderService.get(riderId)
            else if (employerId)
                promise = EmployerService.get(employerId)
            else if (officialId)
                promise = OfficialService.get(officialId)
            else if (simpleUserId)
                promise = SimpleUserService.get(simpleUserId)
            else if (merchantId)
                promise = MerchantService.get(merchantId)

            if (promise) promise
                .then(user => {
                    store.commit('setRouteUser', user)
                    logUser('RouteUser set', user, user)
                    next()
                }).catch((err) => {
                    store.commit('resetRouteUser')
                    console.warn('Error fetching user for route: ', to)
                    next(err)
                })
            else
                next()
        })
    }
}
