const SET_USER = 'SET_USER'
const SET_DASHBOARD = 'SET_DASHBOARD'
export const REMOVE_USER = 'REMOVE_USER'
const SET_LOGIN_REF = 'SET_LOGIN_REF'

const state = {
    user: {},
    authenticated: false,
    dashboard: null,
    login_ref: undefined
}

const mutations = {
    [SET_USER] (state, user) {
        state.user = user
        state.authenticated = true
    },

    [REMOVE_USER] (state) {
        state.user = {}
        state.authenticated = false
    },

    [SET_DASHBOARD] (state, dashboard) {
        state.dashboard = dashboard
    },

    [SET_LOGIN_REF] (state, ref) {
        state.login_ref = ref
    }
}

const getters = {
    user: store => store.user,
    dashboard: store => store.dashboard,
    login_ref: store => store.login_ref
}

export default {
    state,
    mutations,
    getters
}
