import CRUDService from 'common/services/crud'
import querystring from 'querystring'
import { stripEmptyKeys } from 'common/utils'

class SimpleUserCRUDService extends CRUDService {
    constructor() {
        super('simple_user')
    }

    listAtVenue = (venueId, params = {}, params2 = {}) => !venueId
        ? Promise.reject('Missing venue ID.')
        : this.$http.get(this.url + 'listAtVenue/', { params: { venue: venueId, ...params, ...params2 } })

}

const service = new SimpleUserCRUDService()
export default service

class _SimpleUserParticipationService extends CRUDService {
    constructor() {
        super('simpleUserParticipation')
    }
}

export const SimpleUserService = new _SimpleUserParticipationService()


class _SimpleUserTransactionService extends CRUDService {
    constructor() {
        super('simpleUserTransaction')
    }


}

export const SimpleUserTransactionService = new _SimpleUserTransactionService()

class _SimpleUserTransactionReportService extends CRUDService {
    constructor() {
        super('simpleUserTransactionsReport')
    }

    generatePDF = (params = {}) =>
        window.open(this.url + 'generatePDF/?' + querystring.stringify(stripEmptyKeys(params)))

    generateCSV = (params = {}) =>
        window.open(this.url + 'generateCSV/?' + querystring.stringify(stripEmptyKeys(params)))

    generateSummaryPDF = (params = {}) =>
        window.open(this.url + 'generateSummaryPDF/?' + querystring.stringify(stripEmptyKeys(params)))

}

export const SimpleUserTransactionReportService = new _SimpleUserTransactionReportService()
