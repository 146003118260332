import { setDashboard } from 'Auth/service'
import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { App } from 'index'

export default [
    {
        name: 'signin.dashboard',
        path: '/signin/',
        component: () => import('./_wrapper.vue'),
        redirect: { name: 'signin.list' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: 'signin'
        },
        beforeEnter(to, from, next) {
            setDashboard('signin')
            const assignedVenueID = App.$auth.user().assigned_venue
            if (!assignedVenueID) {
                App.notify('User doesn\'t have an assigned venue!', 'danger')
                next(false)
                return
            }

            VenueService.get(assignedVenueID).then((venue) => {
                setVenue(venue)
                next()
            }).catch((err) => {
                console.error(err)
                unsetVenue()
                next(err)
                throw err
            })
        },
        children: [
            {
                name: 'signin.list',
                path: 'list',
                component: () => import('./SigninDashboard.vue'),
                meta: { label: () => 'Sign-In view' }
            }
        ]
    }
]
