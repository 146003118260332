import { App } from 'index'
import store from 'store'
import Axios from 'axios'
import * as Sentry from '@sentry/browser'
import { setVenue, default as VenueService } from 'Venue/service'

Axios.interceptors.response.use(function (response) {

    return new Promise((resolve, reject) => {

        if (response.request.responseURL.indexOf('/api/token/auth/') !== -1 && response.data.token && App.$root.whitelabelVenueId) {
            Axios.defaults.headers.Authorization = 'Bearer ' + response.data.token
            VenueService.get(App.$root.whitelabelVenueId).then(record => {
                setVenue(record)
                resolve(response)
            }).catch(err => reject(err))
        } else
            resolve(response)
    })
}, err => Promise.reject(err))

export function login({ username, password }) {
    if (!username || !password)
        return Promise.reject({ response: { data: { '__all__': [ 'Missing parameters' ] } } })

    return new Promise((resolve, reject) => {
        App.$auth.login({ data: { username, password } })
            .then(() => {
                Sentry.setUser(App.$auth.user())
                resolve(App.$auth.user())
            }).catch(err => {
                removeUser()
                reject(err)
            }
        )
    })
}

export const requestPasswordReset = ({ email }) => !email
    ? Promise.reject('No email specified.')
    : Axios.post('/_passwordReset/', { email })

export const resetPassword = ({ uidb64, token, password, password2 }) => (!uidb64 || !token || !password || !password2)
    ? Promise.reject('Missing params.')
    : Axios.post(`/_passwordReset/${uidb64}/${token}/`, { password, password2 })

export function setUser(user) {
    store.commit('SET_USER', user)
}

export function setDashboard(dashboard) {
    store.commit('SET_DASHBOARD', dashboard)
}

export function removeUser() {
    store.commit('REMOVE_USER')
}


