import Vue from 'vue'

import Confirm from 'common/modal/confirm.vue'
import VueUploadComponent from 'vue-upload-component'
import FileUploadModal from 'common/modal/FileUploadModal.vue'
import LogModal from 'common/modal/LogModal.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import MobileBurgerNavAddon from 'Auth/MobileBurgerNavAddon.vue'
import YesNoIcon from './icons/YesNoIcon.vue'
import WarningIcon from './icons/WarningIcon.vue'
import ToggleButton from 'vue-js-toggle-button'
import Popover from 'vue-js-popover'
import VueConfirmDialog from 'vue-confirm-dialog/src'

// other - font awesome icons
import '@fortawesome/fontawesome-free/css/all.min.css'

// other - ionicons
import 'ionicons-npm/css/ionicons.min.css'

// other
import VueCkeditor from 'vue-ckeditor2'

Vue.component('VueCkeditor', VueCkeditor)
Vue.component('MoonLoader', MoonLoader)
Vue.component('Confirm', Confirm)
Vue.component('FileUpload', VueUploadComponent)
Vue.component('FileUploadModal', FileUploadModal)
Vue.component('LogModal', LogModal)
Vue.component('MobileBurgerNavAddon', MobileBurgerNavAddon)
Vue.component('YesNoIcon', YesNoIcon)
Vue.component('WarningIcon', WarningIcon)
Vue.component('BCheckbox', require('./forms/bCheckbox.vue').default)
Vue.use(ToggleButton)
Vue.use(Popover, { tooltip: true })
Vue.use(VueConfirmDialog)
