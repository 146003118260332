import Axios from 'axios'
import { API_URL } from 'const'
import { App } from 'index'

export default class CRUDService {
    constructor(url_prefix) {
        if (!url_prefix)
            throw new Error('No url_prefix specified')

        this.url = `${API_URL}/${url_prefix}/`
        this.$http = Axios
    }

    getObjectURL = (id) =>
        `${this.url}${id}/`

    // extraParams is for curried functions
    list = (params, extraParams = {}) =>
        this.$http.get(this.url, { params: { ...params, ...extraParams } })

    get = (id, params) =>
        (!id)
            ? Promise.reject('Missing id.')
            : this.$http.get(this.getObjectURL(id), { params })

    save = (record, params = {}) =>
        (record.id)
            ? this.$http.patch(this.getObjectURL(record.id), record, { params })
                .then((response) => {
                    App.notify('Saved successfully.', 'success')
                    return response
                })
            : this.$http.post(this.url, record, { params })
                .then((response) => {
                    App.notify('Saved successfully.', 'success')
                    return response
                })

    remove = (id, params) =>
        (!id)
            ? Promise.reject('Missing id.')
            : this.$http.delete(this.getObjectURL(id), { params })
                .then((response) => {
                    App.notify('Record deleted.', 'warning')
                    return response
                })
                .catch(err => {
                    if (err.response.status === 404)
                        App.notify('Error: Record doesn\'t exist anymore.', 'warning')
                    else
                        return Promise.reject(err)
                })

    createMultiple = (records) => records.length ?
        this.$http.post(this.url, records) : Promise.reject('No records specified.')
}
