<template>
    <div>
        <div class="navbar-divider is-hidden-tablet"/>
        <div class="navbar-item is-tab is-hidden-tablet">
            <language-switcher :asIcons="true" class="is-pulled-right" @click="toggleHamburger"/>
            <span v-translate>Switch language:</span>
        </div>
        <a v-if="$auth.check()" @click.prevent="logout()"
           v-translate class="navbar-item is-tab is-hidden-tablet">Logout</a>
    </div>
</template>

<script>
    import LanguageSwitcher from 'common/LanguageSwitcher.vue'
    import { mapMutations } from 'vuex'

    export default {
        components: {
            LanguageSwitcher
        },

        methods: {
            ...mapMutations([ 'toggleHamburger' ]),
            logout() {
                this.$auth.logout()
                this.$router.go('/')
            }
        }
    }
</script>
