export const SET_ACTIVE_VENUE = 'SET_ACTIVE_VENUE'
export const UNSET_ACTIVE_VENUE = 'UNSET_ACTIVE_VENUE'

const state = { activeVenue: null }

const mutations = {
    [SET_ACTIVE_VENUE](state, venue) {
        state.activeVenue = venue
    },

    [UNSET_ACTIVE_VENUE](state) {
        state.activeVenue = null
    }
}

const getters = { activeVenue: store => store.activeVenue }

export default {
    state,
    mutations,
    getters
}
