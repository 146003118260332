import CRUDService from 'common/services/crud'
import { App } from 'index'

class RiderCRUDService extends CRUDService {
    constructor() {
        super('rider')
    }

    listHorses = (riderId, params = {}, extraParams = {}) => {
        console.log(riderId, params, extraParams)
        return (!riderId)
            ? Promise.reject('Missing riderId.')
            : this.$http.get(this.getObjectURL(riderId) + 'horses/', { params: { ...params, ...extraParams } });
    }

    assignHorse = (riderId, horseId) =>
        (!riderId || !horseId)
            ? Promise.reject('Missing riderId or horseId.')
            : this.$http.post(this.getObjectURL(riderId) + 'horses/', {
                id: horseId,
                action: 'add'
            })

    unassignHorse = (riderId, horseId) =>
        (!riderId || !horseId)
            ? Promise.reject('Missing riderId or horseId.')
            : this.$http.post(this.getObjectURL(riderId) + 'horses/', {
                id: horseId,
                action: 'remove'
            })

    listGrooms = (riderId, params) =>
        (!riderId)
            ? Promise.reject('Missing riderId.')
            : this.$http.get(this.getObjectURL(riderId) + 'grooms/', { params })

    assignGroom = (riderId, groomId) =>
        (!riderId || !groomId)
            ? Promise.reject('Missing riderId or groomId.')
            : this.$http.post(this.getObjectURL(riderId) + 'grooms/', {
                id: groomId,
                action: 'add'
            })

    unassignGroom = (riderId, groomId) =>
        (!riderId || !groomId)
            ? Promise.reject('Missing riderId or groomId.')
            : this.$http.post(this.getObjectURL(riderId) + 'grooms/', {
                id: groomId,
                action: 'remove'
            })

    getByEmail = (email) =>
        (!email)
            ? Promise.reject('Missing email.')
            : this.$http.post(this.url + 'getByEmail/', { email })

    getByFEIId = (feiId) =>
        (!feiId)
            ? Promise.reject('Missing FEI ID.')
            : this.$http.post(this.url + 'getByFEIId/', { feiId })

    getByNationalID = (nationalID) =>
        (!nationalID)
            ? Promise.reject('Missing National ID.')
            : this.$http.post(this.url + 'getByNationalID/', { nationalID })

    employmentStatus = (riderId) =>
        (!riderId)
            ? Promise.reject('Missing rider ID.')
            : this.$http.get(this.getObjectURL(riderId) + 'employmentStatus/')

    employmentHistory = (riderId, { includeCurrent = true }) =>
        (!riderId)
            ? Promise.reject('Missing rider ID.')
            : this.$http.get(this.getObjectURL(riderId) + 'employmentHistory/')
                .then(records => {
                    if (includeCurrent)
                        return records

                    return records.filter(record => !!record.date_to)
                })

    confirmEmployment = (riderId, employmentId) =>
        (!riderId || !employmentId)
            ? Promise.reject('Missing rider or employment ID.')
            : this.$http.post(this.getObjectURL(riderId) + 'confirmEmployment/', { employmentId })

    cancelEmployment = (riderId, employmentId) =>
        (!riderId || !employmentId)
            ? Promise.reject('Missing rider or employment ID.')
            : this.$http.post(this.getObjectURL(riderId) + 'cancelEmployment/', { employmentId })

    listAtVenue = (venueId, params = {}, params2 = {}) => !venueId
        ? Promise.reject('Missing venue ID.')
        : this.$http.get(this.url + 'listAtVenue/', { params: { venue: venueId, ...params, ...params2 } })

    checkRiderExists = ({ registration_id, email } = {}) => !registration_id
        ? Promise.reject('Missing registration ID.')
        : this.$http.post(this.url + 'checkRiderExists/', {
            registration_id,
            email
        })

    saveHorseRiderMeta = ({ rider, horse, ...data }) => !rider || !horse
        ? Promise.reject('Missing rider or horse ID')
        : this.$http.patch(this.getObjectURL(rider) + 'horseMeta/', {
            rider,
            horse, ...data
        })
            .then((response) => {
                App.notify('Saved successfully.', 'success')
                return response
            })

    copyHorseOwnerData = (rider, source, target) => !rider || !source || !target
        ? Promise.reject('Missing source or target RiderHorse ID, or Rider ID')
        : this.$http.post(this.getObjectURL(rider) + 'copyHorseOwnerData/', {
            source,
            target
        }).then((response) => {
            App.notify('Horse owner data copied successfully.', 'success')
            return response
        })

    getNextBookkeepingRefId = () => this.$http.get(this.url + 'getNextBookkeepingRefId/')

    acceptTC = ({ riderID }) => !riderID
        ? Promise.reject('No rider ID specified.')
        : this.$http.patch(this.getObjectURL(riderID) + 'acceptTC/')

}

const service = new RiderCRUDService()

export default service
export { service as RiderService }

export const horseRidingEmploymentStatus = {
    NOT_REGISTERED: 'Not registered',
    INDEPENDENT: 'Independent',
    EMPLOYED: 'Employed'
}
export const getHorseRidingEmploymentOptions = () => Object.keys(horseRidingEmploymentStatus)
    .map(key => ({ id: key, name: App.$gettext(horseRidingEmploymentStatus[key]) }))


export const ahvTaxAdminOverrideOptions = {
    NO_OVERRIDE: 'No override - use defaults',
    FORCE: 'Apply tax',
    SKIP: 'Skip tax'
}
export const getAhvTaxAdminOverrideOptions = () => Object.keys(ahvTaxAdminOverrideOptions)
    .map(key => ({ id: key, name: App.$gettext(ahvTaxAdminOverrideOptions[key]) }))
