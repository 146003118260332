import { setDashboard } from 'Auth/service'
import { default as VenueService, setVenue, unsetVenue } from 'Venue/service'
import { App } from 'index'

export default [
    {
        name: 'organizer.dashboard',
        path: '/organizer/',
        component: () => import('./Dashboard.vue'),
        redirect: { name: 'organizer.list' },
        meta: {
            label: () => App.$gettext('Dashboard'),
            auth: [ 'organizer', 'showoffice' ]
        },
        beforeEnter(to, from, next) {
            setDashboard('organizer')
            const assigned_venue_id = App.$auth.user().assigned_venue
            if (!assigned_venue_id) {
                App.notify('User doesn\'t have an assigned venue!', 'danger')
                next(false)
                return
            }

            VenueService.get(assigned_venue_id).then((venue) => {
                setVenue(venue)
                next()
            }).catch((err) => {
                console.error(err)
                unsetVenue()
                next(err)
                throw err
            })
        },
        children: [
            {
                name: 'organizer.list',
                path: 'list',
                component: () => import('./List.vue'),
                meta: { label: () => App.$gettext('Organizer view') }
            },
            {
                name: 'organizer.transactionsForRider',
                path: 'rider/:riderId',
                component: () => import('VenueTransaction/List.vue'),
                meta: { label: () => App.$gettext('Rider transactions') }
            },
            {
                name: 'organizer.vetcheck',
                path: 'vetcheck',
                component: () => import('VetCheck/VetCheckDashboard.vue'),
                meta: { label: () => App.$gettext('Vet-check view') }
            },
            {
                name: 'organizer.participants',
                path: 'participants',
                redirect: { name: 'organizer.participants.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'organizer.participants.list',
                        path: 'list',
                        component: () => import('VenueParticipation/List.vue'),
                        meta: { label: () => App.$gettext('Participants') }
                    }
                ]
            },
            {
                name: 'organizer.signin',
                path: 'signin',
                component: () => import('../SigninDashboard/SigninDashboard.vue'),
                meta: { label: () => App.$gettext('Sign-In dashboard') }
            },
            /* Welcome desk */

            {
                name: 'organizer.welcomedesk',
                path: 'welcomedesk',
                redirect: { name: 'organizer.welcomedesk.list' },
                component: { render: h => h('router-view') },
                meta: { label: () => 'Attendees' },
                children: [
                    {
                        name: 'organizer.welcomedesk.list',
                        path: 'list',
                        component: () => import('WelcomeDesk/AttendeeList.vue'),
                        meta: { label: () => 'Attendees' }

                    },
                    {
                        name: 'organizer.welcomedesk.add',
                        path: 'add',
                        component: () => import('WelcomeDesk/Detail.vue'),
                        meta: { label: () => App.$gettext('Add attendee') }
                    }
                ]
            },


            {
                name: 'organizer.officials',
                path: 'officials',
                redirect: { name: 'organizer.officials.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'organizer.officials.list',
                        path: 'list',
                        component: () => import('OfficialVenueParticipation/List.vue'),
                        meta: { label: () => App.$gettext('Officials Participants') }
                    },
                    {
                        name: 'organizer.officials.add',
                        path: 'add',
                        component: () => import('OfficialVenueParticipation/Detail.vue'),
                        meta: { label: () => App.$gettext('Add Participation') }
                    },
                    {
                        name: 'organizer.officials.new',
                        path: 'add',
                        component: () => import('Official/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Add an Official'), addNew: true }
                    },
                    {
                        name: 'organizer.official.profile',
                        path: ':officialId/profile',
                        component: () => import('Official/tabs/Profile.vue'),
                        meta: { label: () => App.$gettext('Official profile') }
                    }
                ]
            },
            {
                name: 'organizer.startlists',
                path: 'startlists',
                redirect: { name: 'startlists.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'organizer.startlists.list',
                        path: 'list',
                        component: () => import('Competition/StartLists.vue'),
                        meta: { label: () => App.$gettext('Start list') }
                    },
                    {
                        name: 'organizer.startlists.edit',
                        path: 'competition/:competitionId',
                        component: () => import('Competition/StartList_Detail.vue'),
                        meta: { label: () => App.$gettext('Competition start list') }
                    }

                ]

            },
            {
                name: 'organizer.transactions',
                path: 'transactions',
                component: () => import('VenueTransaction/List.vue'),
                meta: { label: () => App.$gettext('Transactions') },
                children: [
                    {
                        name: 'organizer.transactions.edit',
                        path: 'edit/:transactionId',
                        component: () => import('VenueTransaction/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit Transaction') }
                    },
                    {
                        name: 'organizer.transactions.add',
                        path: 'new',
                        component: () => import('VenueTransaction/Detail.vue'),
                        meta: { label: () => App.$gettext('Add Transaction') }
                    }
                ]
            },
            {
                name: 'organizer.officialTransactions',
                path: 'officialTransactions',
                component: () => import('VenueTransaction/Officials/List.vue'),
                meta: { label: () => App.$gettext('Official Transactions') },
                children: [
                    {
                        name: 'organizer.officialTransactions.edit',
                        path: 'edit/:transactionId',
                        component: () => import('Official/tabs/TransactionDetail.vue'),
                        meta: { label: () => App.$gettext('Edit Official Transaction') }
                    },
                    {
                        name: 'organizer.officialTransactions.add',
                        path: 'new',
                        component: () => import('Official/tabs/TransactionDetail.vue'),
                        meta: { label: () => App.$gettext('Add Official Transaction') }
                    }
                ]
            },


            {
                name: 'organizer.rankings',
                path: 'rankings',
                redirect: { name: 'organizer.rankings.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'organizer.rankings.list',
                        path: 'list',
                        component: () => import('CompetitionRanking/List.vue'),
                        meta: { label: () => App.$gettext('Rankings') }
                    },
                    {
                        name: 'organizer.rankings.view',
                        path: 'competition/:competitionId',
                        component: () => import('CompetitionRanking/View.vue'),
                        meta: { label: () => App.$gettext('Edit Rankings') }
                    },
                    {
                        name: 'organizer.rankings.add',
                        path: 'competition/:competitionId/add',
                        component: () => import('CompetitionRanking/Detail.vue'),
                        meta: { label: () => App.$gettext('Add Rankings') }
                    },
                    {
                        name: 'organizer.rankings.edit',
                        path: 'competition/:competitionId/edit',
                        component: () => import('CompetitionRanking/Detail.vue'),
                        meta: { label: () => App.$gettext('Add Rankings') }
                    }
                ]
            },
            {
                name: 'organizer.invoices',
                path: 'invoices',
                component: () => import('./Invoices.vue'),
                meta: { label: () => App.$gettext('Invoices view') }
            },
            {
                name: 'organizer.devices',
                path: 'devices',
                redirect: { name: 'organizer.devices.list' },
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'organizer.devices.list',
                        path: 'list',
                        component: () => import('Device/List.vue'),
                        meta: { label: () => App.$gettext('Devices') }
                    },
                    {
                        name: 'organizer.devices.add',
                        path: 'add',
                        component: () => import('Device/Detail.vue'),
                        meta: { label: () => App.$gettext('Add new Device') }
                    },
                    {
                        name: 'organizer.devices.edit',
                        path: 'edit/:id',
                        component: () => import('Device/Detail.vue'),
                        meta: { label: () => App.$gettext('Edit Device') }
                    }
                ]
            }
        ]
    }
]

