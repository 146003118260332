import moment from 'moment'
import store from 'store'
import slugify from 'slugify'
import { arrayize } from './utils'

const n2br = value => {
    if (Array.isArray(value))
        return value.map(v => n2br(v)).join('<br/>')
    return (value === undefined || value === null) ? '' : String(value).replace('\n', '<br>')
}

export default {
    install(Vue) {
        Vue.filter('date', (date, format = 'D.M.Y H:mm') => {
            if (!date) return
            return moment(date, [ 'DD.MM.YYYY', moment.ISO_8601 ], true).format(format)
        })

        Vue.filter('currency', (number, currency) => {
            if (typeof number !== 'number') return number

            if (typeof currency === 'undefined') currency = store.getters.activeVenue.currencyDisplay

            return number.toLocaleString(undefined, {
                maximumFractionDigits: 2, minimumFractionDigits: 2
            }) + ` ${currency}`
        })

        Vue.filter('translate', value => {
            return !value ? '' : Vue.prototype.$gettext(value.toString())
        })

        Vue.filter('n2br', n2br)

        Vue.filter('slugify', value => slugify(value))
        Vue.filter('arrayize', value => arrayize(value))
    }
}
