import { arrayize, isArray, isObject } from 'common/utils'
import Vue from 'vue'
import { App } from 'index'

const FormErrors = Vue.extend({
    name: 'FormErrors',
    props: { state: { required: true, type: Object } },
    created() {
        if (!this.state.__all__) this.state.__all__ = {}
    },
    template: `<div class="help is-danger" v-if="state['__all__'] && state['__all__'].$errors">`
        + `<p v-for="msg in state['__all__'].$errors">{{ msg }}</p>`
        + `</div>`
})

export default {
    install(Vue, options) {
        // Show form errors
        Vue.directive('with-form-errors', {
            inserted(el, bindings, vnode) {
                const node = document.createElement('div')
                el.appendChild(node)

                new FormErrors({
                    el: node,
                    propsData: { state: vnode.context[ bindings.expression || 'formstate' ] }
                })
            }
        })

        // Add handleBackendErrorResponse method
        Vue.mixin({
            methods: {
                resetServerErrors(formstate = this.formstate) {
                    if (!formstate || !formstate._reset)
                        return

                    formstate._reset()
                    formstate.$submitted = true

                    this.$delete(formstate, '__all__')
                    Object.keys(formstate).forEach(property => {
                        const field = formstate[ property ]
                        if (field.$error && field.$error.server)
                            this.$delete(formstate[ property ].$error, 'server')
                    })
                },
                handleBackendErrorResponse(error, formstate = this.formstate) {
                    let data = error.response && error.response.data
                    const other = []

                    // Handle formsets
                    // Server response is in format:
                    // { __all__: [{}, {}, {}, {fieldName: ['Error text']}]
                    // ( 4th form in the formset has an error in `fieldName` )
                    if (isObject(data) && Object.keys(data).length === 1 && Object.keys(data)[ 0 ] === '__all__' && Array.isArray(data.__all__) && isObject(data.__all__[ 0 ])) {
                        data.__all__.forEach((formErrors, formIndex) => {
                            if (Object.keys(formErrors).length === 0)
                                return
                            Object.keys(formErrors).forEach(fieldName => {
                                const prefixedFieldName = `${ formIndex }-${ fieldName }`
                                if (typeof formstate[ prefixedFieldName ] === 'undefined' || fieldName === '__all__') {
                                    missing[ fieldName ] = arrayize(data[ fieldName ])
                                    return
                                }
                                this.$set(formstate[ prefixedFieldName ].$error, 'server', arrayize(formErrors[ fieldName ]))

                                const msg = App.$gettext('Check errors above.')
                                if (!other.includes(msg))
                                    other.push(msg)
                            })
                        })
                        delete data.__all__
                    }

                    if (!isObject(data))
                        if (isArray(data) && isObject(data[ 0 ])) {
                            data = data.reduce((a, b) => {
                                return { ...a, ...b }
                            }, {})
                        } else
                            data = { '__all__': arrayize(data) }

                    this.$delete(formstate, '__all__')

                    const missing = {}
                    Object.keys(data).forEach(fieldName => {
                        if (typeof formstate[ fieldName ] === 'undefined' || fieldName === '__all__')
                            missing[ fieldName ] = arrayize(data[ fieldName ])
                        else
                            this.$set(formstate[ fieldName ].$error, 'server', arrayize(data[ fieldName ]))
                    })

                    Object.keys(missing).forEach(fieldName => {
                        if ([ '__all__', 'message' ].includes(fieldName))
                            other.push(`${ missing[ fieldName ].join(', ') }`)
                        else
                            other.push(`${ fieldName }: ${ missing[ fieldName ].join(', ') }`)
                    })

                    if (other.length)
                        this.$set(formstate, '__all__', { $errors: other })
                },

                catchBackendError(err) {
                    let msg = err.response
                    msg = err.response.data || msg
                    msg = err.response.data.message || msg
                    msg = err.response.data.error || msg
                    this.notify(msg, 'warning')
                }
            }
        })
    }
}
